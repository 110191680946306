.scroll-carousel {
  display: flex;
  width: 150vw;
  will-change: transform;
  transition: transform 0.4s ease-out;

  @media screen and (max-width: 1600px) {
    transition: transform 0.3s ease-out;
  }

  &__title {
    font-size: 35px;
    font-weight: 420;
  }

  &__clipped {
    margin-top: 30px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__clipped.visible {
    opacity: 1;
  }

  &__container {
    max-width: 166px;
    margin: 0 30px;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.mb-scroll-carousel {
  display: block;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__image-container {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    align-items: center;
    justify-items: center;
  }
}
