$black: #000;
$grey: #808080;
$white: #fff;

$micronav: #76b957;
$globalats: #e9b400;
$quadrant: #00a9e0;
$primary: var(--primary-color);

body {
  --primary-color: #{$micronav};
}

body.global-ats {
  --primary-color: #{$globalats};
}

body.quadrant {
  --primary-color: #{$quadrant};
}

// primary colours

$dark: #003865;
$off-white: #e5ebef;

// secondary colours

$success-color: #3a9b39;
$warning-color: #edad31;
$error-color: #d32929;
$blue: #094474;
$dark-blue: #001e36;

// background colours

$bg--black: $black;
$bg--white: $white;
$bg--off-white: $off-white;
$bg--dark: $dark;
$bg--primary: $primary;
$bg--blue: $blue;
$bg--dark-blue: $dark-blue;

// text colours

$text--black: $black;
$text--white: $white;
$text--dark: $dark;
$text--primary: $primary;

// button colours

$button--bg: #003865;
$button--hover: #002a4c;
