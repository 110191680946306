/*=============================================
=            Careers Carousel            =
=============================================*/

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.careers-carousel {
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding-right: 0;
    }

    &__wrapper {
        padding: 60px 0 130px;
        color: $text--dark;
        gap: 30px;

        @include media-breakpoint-down(xs) {
            padding-bottom: 180px;
        }
    }

    &__header {
        width: 100%;
        gap: 10px;
    }

    &__heading {
        font-family: $font-family--alt;
        font-size: 35px;
        font-weight: 450;
        line-height: 120%;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            font-weight: 450;
            line-height: 130%;
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    &__item {
        background-color: $bg--white;
        padding: 30px;
        white-space: normal;
        gap: 15px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        &-title {
            font-size: 30px;
            font-weight: 700;
            line-height: 120%;

            @include media-breakpoint-down(sm) {
                font-size: 21px;
                line-height: 130%;
            }
        }

        &-subtitle {
            color: $text--dark;
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.15px;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        white-space: normal;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    //  glide styles
    &__slides {
        margin: auto !important;
    }

    &__arrow {
        width: 50px;
        height: 50px;
        color: $text--white;
        background-color: $bg--primary;
        border-radius: 5px;
        z-index: 99;
        font-size: 20px;
        cursor: pointer;

        @include transition;

        @include media-breakpoint-down(sm) {
            width: 40px;
            height: 40px;
        }

        &:hover {
            color: $text--white;
            background-color: $bg--dark;
        }

        &.glide__arrow--disabled {
            background-color: rgb(0, 56, 101, 0.05);
            color: rgb(0, 56, 101, 0.3);
            cursor: default;
            pointer-events: none;
            display: none;

            .careers-carousel__arrow-icon {
                display: none;
            }
        }

        &-icon {
            transform: translateY(1px);
        }

        &-left {
            position: absolute;
            right: 100px;

            @include media-breakpoint-down(xs) {
                right: 90px;
            }
        }

        &-right {
            position: absolute;
            right: 30px;
        }
    }

    &__controls {
        position: absolute;
        top: calc(100% + 30px);
        right: 0;

        @include media-breakpoint-down(xs) {
            top: calc(100% + 85px);
        }
    }

    .link {
        margin-top: auto;
    }

    &__range {
        width: 200px !important;
        background: $bg--off-white;
        border-radius: 8px;
        height: 6px;
        outline: none;
        border-radius: 8px;
        -webkit-appearance: none;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        @include transition;

        &:focus {
            border-color: $white;
        }

        &-container {
            position: absolute;
            top: calc(100% + 45px);
            left: 120px;
            width: 100%;

            @include media-breakpoint-down(lg) {
                left: 30px;
            }
        }
    }

    input[type='range']::-webkit-slider-thumb {
        width: 6px;
        width: var(--thumb-size);
        height: 6px;
        border-radius: 8px;
        opacity: 1;
        background: $bg--primary;
        -webkit-appearance: none;
        cursor: pointer;
    }
}

body.dark-mode {
    .careers-carousel {
        &__wrapper {
            color: $text--white;
        }

        &__item {
            background-color: $bg--dark;
            color: $text--white;

            &-subtitle {
                color: $text--white;
            }
        }

        &__arrow:hover {
            background-color: white;

            .careers-carousel__arrow-icon {
                color: $text--dark;
            }
            .careers-carousel__arrow-icon:focus {
                color: $text--dark;
            }
        }
    }
}

/*=====  End of Careers Carousel  ======*/
