/*=============================================
=            Plain Text Component            =
=============================================*/

.plain-text {
  width: 100%;

  &__wrapper {
    padding: 60px 0;
    color: $text--dark;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%; 
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      font-weight: 450;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__link {
    margin-top: 20px;
  }
}

body.dark-mode {
  .plain-text__wrapper {
    color: $text--white;
  }
}

/*=====  End of Plain Text Component  ======*/
