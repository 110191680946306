/*=============================================
=            Item List            =
=============================================*/

.item-list {
  padding: 60px 0;
  color: $text--dark;

  &__inner {
    gap: 60px;
  }

  &__header {
    width: 100%;
    gap: 30px;
    max-width: 840px;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%; 
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%; 
    }
  }
  
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    row-gap: 40px;
    column-gap: 160px;
    width: 100%;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 30px
    }
  }

  &__card {
    gap: 30px;

    @include media-breakpoint-down(md) {
      gap: 20px
    }
  }

  &__icon {
    height: 100%;
    width: 100%;

    &-container {
      min-height: 40px;
      max-height: 40px;
      min-width: 40px;
      max-width: 40px;

      @include media-breakpoint-down(md) {
        min-height: 30px;
        max-height: 30px;
        min-width: 30px;
        max-width: 30px;
      }
    }
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.5px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%; 

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__content {
    gap: 10px;
  }
}

body.dark-mode {
  .item-list {
    color: $text--white;
  }
}

/*=====  End of Item List  ======*/
