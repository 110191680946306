/*=============================================
=            Card Grid            =
=============================================*/

.card-grid {
    padding: 60px 0;
    color: $text--dark;
    gap: 24px;

    &__inner {
        gap: 16px;

        @include media-breakpoint-down(md) {
            gap: 42px;
            flex-direction: column;
            padding: 0 !important;
        }
    }

    &__trigger {
        color: $text--white;
        gap: 10px;
        padding: 4px 6px;
        font-family: inherit;
        border-radius: 4px;
        cursor: pointer;

        @include transition;

        &:hover {
            background-color: $bg--dark-blue;
        }

        &-text {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
        }

        &-left {
            gap: 10px;
            width: 100%;
        }

        &-icon,
        &-chevron {
            font-size: 24px;

            @include transition;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__filters {
        background-color: $bg--blue;
        padding: 16px 10px;
        border-radius: 8px;
        gap: 24px;
        max-width: 240px;
        align-self: stretch;

        @include media-breakpoint-down(md) {
            max-width: none;
            border-radius: 0px;

            .filters {
                display: none;
            }

            &--active {
                .filters {
                    display: flex;
                }

                .card-grid__trigger-chevron {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 16px;
        width: 100%;
        transition: all 0.3s ease;

        @include transition;

        @include media-breakpoint-down(md) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            row-gap: 30px;
            column-gap: 0;
        }
    }

    &__card {
        overflow: hidden;
        border-radius: 8px;
        background-color: $bg--blue;
        grid-column: span 4;
        box-shadow: none;

        @include transition;

        &:hover,
        &:focus {
            color: $text--white;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 29px 0px;

            .card-grid__card-image {
                transform: scale(1.05);
            }

            .button {
                color: $text--white;
            }
        }

        @include media-breakpoint-between('lg', 'xl') {
            grid-column: span 6;

            &:nth-child(3n) {
                grid-column: span 12;
                flex-direction: row;

                .card-grid__card-image-container {
                    max-height: none;
                    min-height: 480px;
                    flex: 1;
                }

                .card-grid__card-content {
                    flex: 0.8729;
                    gap: 24px;
                    padding: 48px 30px;
                }

                .card-grid__card-title {
                    padding-bottom: 0;
                }

                .card-grid__card-badge {
                    top: 20px;
                    left: 27px;
                }

                .link {
                    margin-top: 0;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            grid-column: span 12;
        }

        @include media-breakpoint-down(md) {
            grid-column: span 6;
        }

        @include media-breakpoint-down(sm) {
            grid-column: span 12;
        }

        &-row {
            gap: 10px;
            width: 100%;
        }

        &-date {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: -0.15px;
        }

        &-type {
            font-weight: 500;
            font-size: 14.06px;
            line-height: 20px;
            text-transform: uppercase;
            margin-left: auto;
        }

        &-badge {
            font-family: $font-family--alt;
            font-size: 12px;
            line-height: 16px;
            font-weight: 420;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 6px 12px;
            border-radius: 20px;
            text-transform: uppercase;
            background-color: $bg--white;
            color: $text--dark;
        }

        &-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            vertical-align: middle;
            transform: scale(1);

            @include transition;

            &-container {
                min-height: 200px;
                max-height: 200px;
                position: relative;
                overflow: hidden;
            }
        }

        &-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.4px;
            padding-bottom: 8px;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 130%;
            }
        }

        &-info {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: -0.15px;
        }

        &-content {
            padding: 16px 30px 24px;
            gap: 16px;
            height: 100%;

            .button {
                margin-top: auto;
            }
        }

        &--feature {
            grid-column: span 12;
            flex-direction: row;

            .card-grid__card-image-container {
                max-height: none;
                min-height: 480px;
                flex: 1;
            }

            .card-grid__card-content {
                flex: 0.8729;
                gap: 24px;
                padding: 48px 30px;
            }

            .card-grid__card-title {
                padding-bottom: 0;
            }

            .card-grid__card-badge {
                top: 20px;
                left: 27px;
            }

            .button {
                margin-top: 0;
            }
        }
    }

    &__info {
        grid-column: span 12;
        min-height: 200px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }
}

body.dark-mode {
    .card-grid {
        color: $text--white;

        &__card {
            &-badge {
                color: $text--dark;
            }
        }
    }
}

// Add media queries for mobile devices
@include media-breakpoint-down (768px) {
  .card-grid__card--feature {
    grid-column: span 12;
    flex-direction: column;

    .card-grid__card-image-container {
      min-height: auto;
      flex: none;
    }

    .card-grid__card-content {
      flex: none;
      padding: 24px 15px;
    }

    .card-grid__card-title {
      padding-bottom: 10px;
    }

    .card-grid__card-badge {
      top: 10px;
      left: 15px;
    }

    .button {
      margin-top: 10px;
    }
  }
}

/*=====  End of Card Grid  ======*/
