/*=============================================
=            Hero            =
=============================================*/

.hero {
  position: relative;
  gap: 30px;
  color: $text--white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  overflow: hidden;

  &--large {
    height: 750px;

    .hero__background {
      height: 750px;
    }

    @include media-breakpoint-down(md) {
      height: 600px;

      .hero__background {
        height: 600px;
      }
    }
  }

  &--small {
    min-height: 540px;

    .hero__background {
      min-height: 540px;
    }

    @include media-breakpoint-down(md) {
      min-height: 500px;

      .hero__background {
        min-height: 500px;
      }
    }
  }

  &__background-wrapper {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; /* Positioned behind all other content */

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $bg--dark;
      opacity: 0.5;
      z-index: 0;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: url("/assets/img/img/hero-shape.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      z-index: 0;
    }

    img {
      max-height: 750px;
      width: 100%;
      height: 100%;
      max-height: 750px;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        max-height: fit-content;
      }
    }
  }

  &__main {
    width: 100%;
    max-width: 660px;
    padding: 120px 0;
    gap: 30px;
    position: absolute;
    left: 120px;
    bottom: 0px;

    @include media-breakpoint-down(lg) {
      left: 30px;
    }

    @include media-breakpoint-down(md) {
      padding: 60px 0;
      width: calc(100% - 60px);
    }
  }

  &__inner {
    position: relative;

    @include media-breakpoint-down(md) {
      position: static;
    }
  }

  &__heading {
    gap: 10px;
  }

  &__title {
    font-family: $font-family--alt;
    font-size: 40px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      line-height: 130%;
    }
  }

  &__subtitle {
    font-size: 25px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.5px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

/*=====  End of Hero  ======*/
