/*=============================================
=            Link            =
=============================================*/

.link {
  cursor: pointer;
  padding: 10px 20px;
  font-family: $font-family--alt;
  font-size: 15px;
  font-weight: 450;
  line-height: 20px;
  gap: 10px;
  min-width: max-content;
  text-transform: uppercase;
  border-radius: 5px;

  &__error-page {
    margin-top: 40px
  }

  @include transition; 

  &--underline .link__label{
    text-decoration: underline;
  }

  &--primary {
    background-color: $white;
    border: 2px solid $bg--primary;
    color: $text--dark;

    &-simple {
      background-color: transparent;
      color: $text--primary;
      padding: 0;
    }

  &:hover, &:focus {
    background-color: $button--hover;
    color: $text--white;
    }
  }
}

body.dark-mode {
  .link--primary {
    background-color: $button--bg;
    border: 2px solid $bg--primary;
    color: $text--white;

    &:hover, &:focus {
      background-color: $button--hover;
    }
  }
}

/*=====  End of Link  ======*/
