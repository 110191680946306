@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=siq1akn&ht=tk&f=10294.10295.10296.10297.10298.10299.10300.10301.10302.10303.10304.10305&a=77453251&app=typekit&e=css");
@import url("https://p.typekit.net/p.css?s=1&k=siq1akn&ht=tk&f=10294.10295.10296.10297.10298.10299.10300.10301.10302.10303.10304.10305.32202.32203.32204.32205.32206.32207&a=77453251&app=typekit&e=css");
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}
@font-face {
  font-family: "stolzl";
  src: url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
.tk-brandon-grotesque {
  font-family: "brandon-grotesque", sans-serif;
}

.tk-stolzl {
  font-family: "stolzl", sans-serif;
}

body {
  --primary-color: #76b957;
}

body.global-ats {
  --primary-color: #e9b400;
}

body.quadrant {
  --primary-color: #00a9e0;
}

#embedContainer {
  max-width: 1440px !important;
  margin: 0 auto !important;
  width: 100% !important;
  padding: 60px !important;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-out {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
html,
body {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  color: #000;
  scroll-behavior: smooth;
}

body {
  background-color: #F4F8FB;
  background-image: url("/assets/img/img/body-pattern.svg");
  background-size: contain;
  background-repeat: repeat-y;
  background-position: center;
}
@media (max-width: 767.98px) {
  body {
    background-size: auto;
  }
}

body.dark-mode {
  background-image: url("/assets/img/img/body-pattern-dark.svg");
  background-color: #003865;
}

body.no-background {
  background-image: none;
}

.container.container {
  margin: 0 auto;
  padding: 0 120px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .container.container {
    padding: 0 30px;
  }
}
.container.container::before, .container.container::after {
  content: none;
}
.container.container--padding-left {
  padding-right: 0;
}
.container.container--full {
  width: 100%;
  margin: 0 auto;
}
.container.container--awkward {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 200px;
}
@media (max-width: 991.98px) {
  .container.container--awkward {
    padding: 0 16px;
  }
}
.container.container--has-margin {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767.98px) {
  .container.container--has-margin {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.glide__slides {
  padding: 10px 0 !important;
}

input[type=range][min="0"][max="0"] {
  display: none;
}

body.global-ats div[data-glide-el=controls] button {
  color: #003865;
}
body.global-ats div[data-glide-el=controls] button:hover, body.global-ats div[data-glide-el=controls] button:focus {
  color: #fff;
}
body.global-ats div[data-glide-el=controls] button.glide__arrow--disabled {
  color: #fff;
}

.errors-container__inner {
  gap: 20px;
}
.errors-container__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 50px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .errors-container__title {
    font-size: 30px;
  }
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}
.flex--wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex--justify-space-around {
  justify-content: space-around;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--align-end {
  align-items: flex-end;
}
.flex--row {
  flex-direction: row;
}
.flex--column {
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: currentColor;
  border: none;
  background: none;
}
*:hover,
*::before:hover,
*::after:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  opacity: 1;
  color: #fff;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
a:hover, a:focus {
  color: currentColor;
  opacity: 1;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
}
img.inline {
  width: unset;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
}

@media (max-width: 479.98px) {
  .hidden--sm-down {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hidden--md-down {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden--lg-up {
    display: none !important;
  }
}
.hidden-element {
  display: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/*=============================================
=            Accordion            =
=============================================*/
.accordion {
  padding: 60px 0;
  color: #003865;
}
.accordion__inner {
  gap: 60px;
  position: relative;
}
.accordion__header {
  width: 100%;
  gap: 30px;
  max-width: 840px;
}
.accordion__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .accordion__heading {
    font-size: 24px;
    line-height: 130%;
  }
}
.accordion__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .accordion__text {
    font-size: 16px;
  }
}
.accordion__main {
  width: 100%;
}
.accordion__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
  user-select: none;
  max-width: 70%;
}
@media (max-width: 767.98px) {
  .accordion__title {
    font-size: 18px;
    line-height: 130%;
    max-width: 90%;
  }
}
.accordion__content {
  cursor: default;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  max-width: 70%;
}
@media (max-width: 767.98px) {
  .accordion__content {
    max-width: none;
    font-size: 16px;
  }
}
.accordion__item {
  width: 100%;
  border-top: 1px solid rgba(0, 56, 101, 0.1);
}
.accordion__item:last-child {
  border-bottom: 1px solid rgba(0, 56, 101, 0.1);
}
.accordion__item:hover {
  cursor: pointer;
}
.accordion__item[open] .accordion__icon--minus {
  display: flex;
}
.accordion__item[open] .accordion__icon--plus {
  display: none;
}
.accordion__item-summary {
  position: relative;
  list-style: none;
  padding: 10px;
}
.accordion__item::-webkit-details-marker {
  display: none;
}
.accordion__icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 26px;
  height: 40px;
  width: 40px;
}
.accordion__icon--minus {
  display: none;
}
.accordion__icon--plus {
  display: flex;
}

body.dark-mode .accordion {
  color: #fff;
}
body.dark-mode .accordion__item {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
body.dark-mode .accordion__item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

/*=====  End of Accordion  ======*/
/*=============================================
=            button            =
=============================================*/
.button {
  cursor: pointer;
  padding: 10px 20px;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 15px;
  font-weight: 450;
  line-height: 20px;
  gap: 10px;
  min-width: max-content;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.button:disabled {
  pointer-events: none;
}
.button--primary {
  background-color: #fff;
  border: 2px solid var(--primary-color);
  color: #003865;
}
.button--primary-simple {
  background-color: transparent;
  color: var(--primary-color);
  padding: 0;
}
.button--primary:hover, .button--primary:focus {
  background-color: #002a4c;
  color: #fff;
}

body.dark-mode .button--primary {
  background-color: #003865;
  border: 2px solid var(--primary-color);
  color: #fff;
}
body.dark-mode .button--primary:hover, body.dark-mode .button--primary:focus {
  background-color: #002a4c;
}

/*=====  End of button  ======*/
/*=============================================
=            Card Grid            =
=============================================*/
.card-grid {
  padding: 60px 0;
  color: #003865;
  gap: 24px;
}
.card-grid__inner {
  gap: 16px;
}
@media (max-width: 767.98px) {
  .card-grid__inner {
    gap: 42px;
    flex-direction: column;
    padding: 0 !important;
  }
}
.card-grid__trigger {
  color: #fff;
  gap: 10px;
  padding: 4px 6px;
  font-family: inherit;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.card-grid__trigger:hover {
  background-color: #001e36;
}
.card-grid__trigger-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.card-grid__trigger-left {
  gap: 10px;
  width: 100%;
}
.card-grid__trigger-icon, .card-grid__trigger-chevron {
  font-size: 24px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (min-width: 768px) {
  .card-grid__trigger {
    display: none;
  }
}
.card-grid__filters {
  background-color: #094474;
  padding: 16px 10px;
  border-radius: 8px;
  gap: 24px;
  max-width: 240px;
  align-self: stretch;
}
@media (max-width: 767.98px) {
  .card-grid__filters {
    max-width: none;
    border-radius: 0px;
  }
  .card-grid__filters .filters {
    display: none;
  }
  .card-grid__filters--active .filters {
    display: flex;
  }
  .card-grid__filters--active .card-grid__trigger-chevron {
    transform: rotate(180deg);
  }
}
.card-grid__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  width: 100%;
  transition: all 0.3s ease;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .card-grid__grid {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .card-grid__grid {
    row-gap: 30px;
    column-gap: 0;
  }
}
.card-grid__card {
  overflow: hidden;
  border-radius: 8px;
  background-color: #094474;
  grid-column: span 4;
  box-shadow: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.card-grid__card:hover, .card-grid__card:focus {
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 29px 0px;
}
.card-grid__card:hover .card-grid__card-image, .card-grid__card:focus .card-grid__card-image {
  transform: scale(1.05);
}
.card-grid__card:hover .button, .card-grid__card:focus .button {
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1249.98px) {
  .card-grid__card {
    grid-column: span 6;
  }
  .card-grid__card:nth-child(3n) {
    grid-column: span 12;
    flex-direction: row;
  }
  .card-grid__card:nth-child(3n) .card-grid__card-image-container {
    max-height: none;
    min-height: 480px;
    flex: 1;
  }
  .card-grid__card:nth-child(3n) .card-grid__card-content {
    flex: 0.8729;
    gap: 24px;
    padding: 48px 30px;
  }
  .card-grid__card:nth-child(3n) .card-grid__card-title {
    padding-bottom: 0;
  }
  .card-grid__card:nth-child(3n) .card-grid__card-badge {
    top: 20px;
    left: 27px;
  }
  .card-grid__card:nth-child(3n) .link {
    margin-top: 0;
  }
}
@media (max-width: 991.98px) {
  .card-grid__card {
    grid-column: span 12;
  }
}
@media (max-width: 767.98px) {
  .card-grid__card {
    grid-column: span 6;
  }
}
@media (max-width: 479.98px) {
  .card-grid__card {
    grid-column: span 12;
  }
}
.card-grid__card-row {
  gap: 10px;
  width: 100%;
}
.card-grid__card-date {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.15px;
}
.card-grid__card-type {
  font-weight: 500;
  font-size: 14.06px;
  line-height: 20px;
  text-transform: uppercase;
  margin-left: auto;
}
.card-grid__card-badge {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 420;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 6px 12px;
  border-radius: 20px;
  text-transform: uppercase;
  background-color: #fff;
  color: #003865;
}
.card-grid__card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  transform: scale(1);
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.card-grid__card-image-container {
  min-height: 200px;
  max-height: 200px;
  position: relative;
  overflow: hidden;
}
.card-grid__card-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
  padding-bottom: 8px;
}
@media (max-width: 767.98px) {
  .card-grid__card-title {
    font-size: 18px;
    line-height: 130%;
  }
}
.card-grid__card-info {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.15px;
}
.card-grid__card-content {
  padding: 16px 30px 24px;
  gap: 16px;
  height: 100%;
}
.card-grid__card-content .button {
  margin-top: auto;
}
.card-grid__card--feature {
  grid-column: span 12;
  flex-direction: row;
}
.card-grid__card--feature .card-grid__card-image-container {
  max-height: none;
  min-height: 480px;
  flex: 1;
}
.card-grid__card--feature .card-grid__card-content {
  flex: 0.8729;
  gap: 24px;
  padding: 48px 30px;
}
.card-grid__card--feature .card-grid__card-title {
  padding-bottom: 0;
}
.card-grid__card--feature .card-grid__card-badge {
  top: 20px;
  left: 27px;
}
.card-grid__card--feature .button {
  margin-top: 0;
}
.card-grid__info {
  grid-column: span 12;
  min-height: 200px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

body.dark-mode .card-grid {
  color: #fff;
}
body.dark-mode .card-grid__card-badge {
  color: #003865;
}

.card-grid__card--feature {
  grid-column: span 12;
  flex-direction: column;
}
.card-grid__card--feature .card-grid__card-image-container {
  min-height: auto;
  flex: none;
}
.card-grid__card--feature .card-grid__card-content {
  flex: none;
  padding: 24px 15px;
}
.card-grid__card--feature .card-grid__card-title {
  padding-bottom: 10px;
}
.card-grid__card--feature .card-grid__card-badge {
  top: 10px;
  left: 15px;
}
.card-grid__card--feature .button {
  margin-top: 10px;
}

/*=====  End of Card Grid  ======*/
/*=============================================
=            Careers Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.careers-carousel {
  position: relative;
  width: 100%;
}
@media (max-width: 991.98px) {
  .careers-carousel {
    padding-right: 0;
  }
}
.careers-carousel__wrapper {
  padding: 60px 0 130px;
  color: #003865;
  gap: 30px;
}
@media (max-width: 374.98px) {
  .careers-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.careers-carousel__header {
  width: 100%;
  gap: 10px;
}
.careers-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .careers-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.careers-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .careers-carousel__text {
    font-size: 16px;
  }
}
.careers-carousel__item {
  background-color: #fff;
  padding: 30px;
  white-space: normal;
  gap: 15px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.careers-carousel__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .careers-carousel__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.careers-carousel__item-subtitle {
  color: #003865;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.15px;
}
@media (max-width: 479.98px) {
  .careers-carousel__item-subtitle {
    font-size: 16px;
  }
}
.careers-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .careers-carousel__text {
    font-size: 16px;
  }
}
.careers-carousel__slides {
  margin: auto !important;
}
.careers-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .careers-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.careers-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.careers-carousel__arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
  display: none;
}
.careers-carousel__arrow.glide__arrow--disabled .careers-carousel__arrow-icon {
  display: none;
}
.careers-carousel__arrow-icon {
  transform: translateY(1px);
}
.careers-carousel__arrow-left {
  position: absolute;
  right: 100px;
}
@media (max-width: 374.98px) {
  .careers-carousel__arrow-left {
    right: 90px;
  }
}
.careers-carousel__arrow-right {
  position: absolute;
  right: 30px;
}
.careers-carousel__controls {
  position: absolute;
  top: calc(100% + 30px);
  right: 0;
}
@media (max-width: 374.98px) {
  .careers-carousel__controls {
    top: calc(100% + 85px);
  }
}
.careers-carousel .link {
  margin-top: auto;
}
.careers-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.careers-carousel__range:focus {
  border-color: #fff;
}
.careers-carousel__range-container {
  position: absolute;
  top: calc(100% + 45px);
  left: 120px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .careers-carousel__range-container {
    left: 30px;
  }
}
.careers-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .careers-carousel__wrapper {
  color: #fff;
}
body.dark-mode .careers-carousel__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .careers-carousel__item-subtitle {
  color: #fff;
}
body.dark-mode .careers-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .careers-carousel__arrow:hover .careers-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .careers-carousel__arrow:hover .careers-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Careers Carousel  ======*/
/*=============================================
=            Careers Grid            =
=============================================*/
.careers-grid {
  padding: 60px 0;
  color: #003865;
}
.careers-grid__inner {
  gap: 60px;
}
.careers-grid__header {
  width: 100%;
  gap: 20px;
}
.careers-grid__header-left {
  max-width: 600px;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .careers-grid__header-right {
    width: 100%;
  }
}
.careers-grid__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .careers-grid__heading {
    font-size: 24px;
    line-height: 130%;
    text-transform: uppercase;
  }
}
.careers-grid__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .careers-grid__text {
    font-size: 16px;
  }
}
.careers-grid__filters {
  border-radius: 6px;
  min-width: 240px;
  border: 1px solid rgba(0, 56, 101, 0.5);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  padding: 10px 20px;
  position: relative;
  background-color: #fff;
  -webkit-appearance: none;
  background-image: url("/assets/img/img/caret-down-icon.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  color: rgba(0, 56, 101, 0.5);
}
@media (max-width: 767.98px) {
  .careers-grid__filters {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .careers-grid__filters {
    min-width: auto;
    width: 100%;
  }
}
.careers-grid__filters-container {
  gap: 10px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .careers-grid__filters-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.careers-grid__filters-container label {
  margin-bottom: 0;
}
.careers-grid__filter-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .careers-grid__filter-text {
    font-size: 16px;
  }
}
.careers-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 60px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .careers-grid__grid {
    gap: 30px;
  }
}
.careers-grid__item {
  background-color: #fff;
  padding: 30px;
  white-space: normal;
  gap: 15px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.careers-grid__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .careers-grid__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.careers-grid__item-subtitle {
  color: #003865;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.15px;
}
@media (max-width: 479.98px) {
  .careers-grid__item-subtitle {
    font-size: 16px;
  }
}
.careers-grid__item-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .careers-grid__item-text {
    font-size: 16px;
  }
}
.careers-grid__item .link {
  margin-top: auto;
}

body.dark-mode .careers-grid {
  color: #fff;
}
body.dark-mode .careers-grid__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .careers-grid__item-subtitle {
  color: #fff;
}

/*=====  End of Careers Grid  ======*/
/*=============================================
=            Feature Banner            =
=============================================*/
.feature-banner {
  position: relative;
  color: #fff;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .feature-banner {
    min-height: 600px;
  }
}
.feature-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #003865;
  opacity: 0.5;
  z-index: -1;
}
.feature-banner:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("/assets/img/img/hero-shape.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: -1;
}
.feature-banner__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: none;
  visibility: hidden;
  opacity: 0;
}
.feature-banner__video--active {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.feature-banner__video-container {
  white-space: normal;
  position: relative;
  -webkit-mask-image: url("/assets/img/img/feature-banner-mask.svg");
  mask-image: url("/assets/img/img/feature-banner-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-size: cover;
  min-height: 400px;
  max-height: 400px;
  max-width: 1200px;
  margin-top: 105px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .feature-banner__video-container {
    margin-top: 80px;
    min-height: 200px;
    -webkit-mask-size: contain;
    -webkit-mask-image: url("/assets/img/img/feature-banner-mask-mobile.svg");
    mask-image: url("/assets/img/img/feature-banner-mask-mobile.svg");
  }
}
.feature-banner__content {
  width: 100%;
  max-width: 1200px;
  gap: 30px;
  transform: translateY(-60px);
  text-align: center;
}
@media (max-width: 767.98px) {
  .feature-banner__content {
    transform: translateY(0);
  }
}
.feature-banner__heading {
  gap: 10px;
  max-width: 960px;
}
.feature-banner__heading-margin {
  margin-top: 60px;
}
.feature-banner__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 40px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .feature-banner__title {
    font-size: 28px;
    line-height: 130%;
  }
}
.feature-banner__subtitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .feature-banner__subtitle {
    font-size: 18px;
    line-height: 130%;
  }
}
.feature-banner__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .feature-banner__text {
    font-size: 16px;
    line-height: 150%;
  }
}

/*=====  End of Feature Banner  ======*/
/*=============================================
=            Feature Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.feature-carousel {
  position: relative;
  width: 100%;
}
@media (max-width: 991.98px) {
  .feature-carousel {
    padding-right: 0;
  }
}
.feature-carousel__wrapper {
  padding: 60px 0 130px;
  color: #003865;
  gap: 30px;
}
@media (max-width: 374.98px) {
  .feature-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.feature-carousel__header {
  gap: 10px;
}
.feature-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .feature-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.feature-carousel__subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .feature-carousel__subtitle {
    font-size: 16px;
  }
}
.feature-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .feature-carousel__text {
    font-size: 16px;
  }
}
.feature-carousel__item {
  white-space: normal;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.feature-carousel__item-title {
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .feature-carousel__item-title {
    font-size: 24px;
  }
}
.feature-carousel__item-subtitle {
  color: #003865;
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 479.98px) {
  .feature-carousel__item-subtitle {
    font-size: 18px;
    line-height: 130%;
  }
}
.feature-carousel__content {
  padding: 30px;
  height: 100%;
  gap: 15px;
}
.feature-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .feature-carousel__text {
    font-size: 16px;
  }
}
.feature-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.feature-carousel__image-container {
  min-height: 360px;
  max-height: 360px;
  position: relative;
}
@media (max-width: 479.98px) {
  .feature-carousel__image-container {
    min-height: 320px;
    max-height: 320px;
  }
}
.feature-carousel__slides {
  margin: auto !important;
}
.feature-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .feature-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.feature-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.feature-carousel__arrow.glide__arrow--disabled {
  display: none;
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.feature-carousel__arrow.glide__arrow--disabled .feature-carousel__arrow-icon {
  display: none;
}
.feature-carousel__arrow-icon {
  transform: translateY(1px);
}
.feature-carousel__arrow-left {
  position: absolute;
  right: 100px;
}
@media (max-width: 374.98px) {
  .feature-carousel__arrow-left {
    right: 90px;
  }
}
.feature-carousel__arrow-right {
  position: absolute;
  right: 30px;
}
.feature-carousel__controls {
  position: absolute;
  top: calc(100% + 30px);
  right: 0;
}
@media (max-width: 374.98px) {
  .feature-carousel__controls {
    top: calc(100% + 85px);
  }
}
.feature-carousel .link {
  margin-top: auto;
}
.feature-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.feature-carousel__range:focus {
  border-color: #fff;
}
.feature-carousel__range-container {
  position: absolute;
  top: calc(100% + 45px);
  left: 120px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .feature-carousel__range-container {
    left: 30px;
  }
}
.feature-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .feature-carousel__wrapper {
  color: #fff;
}
body.dark-mode .feature-carousel__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .feature-carousel__item-subtitle {
  color: #fff;
}
body.dark-mode .feature-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .feature-carousel__arrow:hover .feature-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .feature-carousel__arrow:hover .feature-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Feature Carousel  ======*/
/*=============================================
=            Filter Dropdown            =
=============================================*/
.filters {
  width: 100%;
  min-width: 200px;
  padding: 0 6px;
}
.filters:nth-of-type(2) {
  position: relative;
  padding-top: 24px;
}
.filters:nth-of-type(2):after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 6px);
  height: 1px;
  background-color: white;
  opacity: 0.5;
  max-width: 298px;
}
@media (min-width: 768px) {
  .filters-list {
    padding: 0px;
  }
  .filters-list .filters__dropdown-button {
    padding: 10px 0 0px;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .filters-list .filters__dropdown-label {
    background-color: #094474;
    color: #fff;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }
  .filters-list .filters__dropdown-label:hover {
    padding-left: 20px;
  }
  .filters-list .filters__dropdown-label--active {
    background-color: #001e36;
  }
  .filters-list .filters__dropdown-label:not(:last-child) {
    border-bottom: none;
  }
  .filters-list .filters__bottom {
    display: none;
  }
  .filters-list .filters__dropdown-content {
    position: static;
    opacity: 1;
    visibility: visible;
    gap: 12px;
    background-color: #094474;
    z-index: 0;
    max-height: none;
  }
  .filters-list .filters__dropdown-button-text {
    font-size: 14.18px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .filters-list .filters__dropdown-button-icon {
    display: none;
  }
}
.filters__bottom {
  padding: 16px 0 0;
  gap: 24px;
  display: none;
}
.filters__bottom--active {
  display: flex;
}
@media (max-width: 479.98px) {
  .filters__bottom {
    align-items: center;
    padding: 16px 8px 0;
    min-height: auto;
  }
}
.filters__dropdowns {
  width: 100%;
  gap: 32px;
}
@media (max-width: 991.98px) {
  .filters__dropdowns {
    gap: 16px;
  }
}
@media (max-width: 374.98px) {
  .filters__dropdowns {
    flex-wrap: wrap;
  }
}
.filters__dropdown {
  flex: 1;
  gap: 10px;
}
.filters__dropdown:first-child .filters__dropdown-button {
  max-width: 451px;
}
@media (max-width: 991.98px) {
  .filters__dropdown:first-child .filters__dropdown-button {
    max-width: none;
  }
}
@media (max-width: 479.98px) {
  .filters__dropdown:nth-child(2) .filters__dropdown-content {
    left: auto;
    right: 0;
  }
}
@media (max-width: 374.98px) {
  .filters__dropdown:nth-child(2) .filters__dropdown-content {
    left: 0;
    right: auto;
  }
}
.filters__dropdown-button {
  font-family: inherit;
  color: #fff;
  position: relative;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 10px 14px 10px 8px;
  flex: 1;
  gap: 8px;
  cursor: pointer;
}
.filters__dropdown-button-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.filters__dropdown-button-icon {
  font-size: 24px;
}
.filters__dropdown-content {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  min-width: 160px;
  max-height: 230px;
  overflow: auto;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.filters__dropdown-content--active {
  opacity: 1;
  visibility: visible;
}
.filters__dropdown-label {
  color: #000;
  width: 100%;
  text-align: start;
  display: block;
  cursor: pointer;
  padding: 8px 20px;
  background-color: #fff;
  font-family: inherit;
  font-weight: 400;
  margin-bottom: 0;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.filters__dropdown-label:not(:last-child) {
  border-bottom: 1px solid #fff;
}
.filters__dropdown-label--active {
  background-color: #001e36;
  color: #fff;
}
.filters__dropdown-label--disabled {
  pointer-events: none;
}
.filters__dropdown-input {
  display: none;
}
.filters__tags {
  width: 100%;
  gap: 10px;
}
.filters__tag {
  font-family: inherit;
  background-color: #001e36;
  color: #fff;
  padding: 2px 12px;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.filters__tag:hover, .filters__tag:focus {
  border-color: #fff;
}
.filters__tag-name {
  font-size: 14.18px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}
.filters__tag-icon {
  font-size: 18px;
}

body.dark-mode .button--primary {
  background-color: #003865;
  border: 2px solid var(--primary-color);
  color: #fff;
}
body.dark-mode .button--primary:hover, body.dark-mode .button--primary:focus {
  background-color: #002a4c;
}

/*=====  End of Filter Dropdown  ======*/
/*=============================================
=            Footer            =
=============================================*/
.footer {
  background-color: #003865;
  color: #fff;
  padding: 120px 0 60px;
  gap: 60px;
}
.footer__error-page {
  padding: 20px 0;
  position: relative;
  background-color: #003865;
}
@media (max-width: 767.98px) {
  .footer {
    gap: 30px;
    padding-top: 60px;
  }
}
.footer__inner {
  gap: 30px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .footer__inner {
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}
@media (max-width: 374.98px) {
  .footer__inner {
    display: flex;
  }
}
.footer__column {
  flex: 1;
}
.footer__column:first-child {
  min-width: 360px;
  margin-right: 90px;
  gap: 30px;
}
@media (max-width: 1249.98px) {
  .footer__column:first-child {
    margin-right: 0px;
    min-width: 300px;
  }
}
@media (max-width: 767.98px) {
  .footer__column:first-child {
    grid-column: span 2;
    margin-bottom: 5px;
  }
}
@media (max-width: 767.98px) {
  .footer:last-child {
    grid-column: span 2;
  }
}
.footer__column:not(:first-child) {
  gap: 10px;
}
.footer__logo {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  object-fit: contain;
}
.footer__logo-container {
  width: 224px;
}
.footer__second-logo {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  object-fit: contain;
}
.footer__second-logo-container {
  width: 224px;
}
.footer__subtitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}
@media (max-width: 767.98px) {
  .footer__subtitle {
    font-size: 16px;
    line-height: 130%;
  }
}
.footer__content {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .footer__content {
    font-size: 16px;
  }
}
.footer__caption {
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
}
@media (max-width: 767.98px) {
  .footer__caption {
    font-size: 16px;
  }
}
.footer__links {
  gap: 10px;
}
.footer__link {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  color: #fff;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .footer__link {
    font-size: 16px;
  }
}
.footer__link:hover, .footer__link:focus {
  color: var(--primary-color);
}
.footer__bottom-inner {
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .footer__bottom-inner {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}
.footer__bottom-left {
  font-family: "stolzl", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}
.footer__bottom-link {
  font-family: "stolzl", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__bottom-link:hover, .footer__bottom-link:focus {
  color: var(--primary-color);
}
.footer__bottom-right {
  gap: 20px;
}
.footer__socials {
  gap: 20px;
}
.footer__social {
  height: 20px;
  width: 20px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.footer__social:hover, .footer__social:focus {
  color: var(--primary-color);
}
.footer__social-icon {
  font-size: 20px;
}

/*=====  End of Footer  ======*/
/*=============================================
=            Media Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.full-media-carousel {
  position: relative;
  width: 100%;
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
}
.full-media-carousel__item {
  white-space: normal;
  position: relative;
  -webkit-mask-image: url("/assets/img/img/full-media-mask.png");
  mask-image: url("/assets/img/img/full-media-mask.png");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-size: cover;
  min-height: 100%;
  max-height: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.full-media-carousel__item--no-mask {
  -webkit-mask-image: none;
  mask-image: none;
}
.full-media-carousel__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .full-media-carousel__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.full-media-carousel__media {
  position: relative;
  z-index: 0;
  height: 100%;
}
.full-media-carousel__media:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #003865;
  opacity: 0.5;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .full-media-carousel__media {
    min-height: 600px;
    max-height: 600px;
  }
}
.full-media-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.full-media-carousel__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 20px;
  text-align: center;
  color: #fff;
  max-width: 420px;
}
@media (max-width: 479.98px) {
  .full-media-carousel__content {
    min-width: 100%;
    padding: 0 100px;
  }
}
@media (max-width: 374.98px) {
  .full-media-carousel__content {
    padding: 0 70px;
  }
}
.full-media-carousel__content .link.link--primary {
  min-width: auto;
}
.full-media-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .full-media-carousel__text {
    font-size: 16px;
  }
}
.full-media-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .full-media-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.full-media-carousel__arrow:hover, .full-media-carousel__arrow:focus {
  color: #fff;
  background-color: #003865;
}
.full-media-carousel__arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.full-media-carousel__arrow-icon {
  transform: translateY(1px);
}
.full-media-carousel__arrow-left {
  position: absolute;
  left: 20px;
}
.full-media-carousel__arrow-right {
  position: absolute;
  right: 20px;
}
.full-media-carousel__controls {
  position: absolute;
  width: 100%;
  top: calc(50% - 25px);
  right: 0;
}
.full-media-carousel .glide__slides {
  padding: 0 !important;
}

/*=====  End of Media Carousel  ======*/
/*=============================================
=            Full Width Media            =
=============================================*/
.full-media {
  margin: 60px auto;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .full-media {
    max-height: none;
  }
}
.full-media--active {
  max-height: none;
}
.full-media__inner {
  position: relative;
  height: 100%;
}
.full-media__content {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: none;
}
@media (max-width: 767.98px) {
  .full-media__content {
    flex-direction: column;
    background: linear-gradient(0deg, #003865 0%, rgba(0, 56, 101, 0) 100%);
  }
}
.full-media__content--active {
  display: flex;
}
.full-media__content-right, .full-media__content-left {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 120px;
}
@media (max-width: 991.98px) {
  .full-media__content-right, .full-media__content-left {
    padding: 30px 30px 30px 120px;
  }
}
@media (max-width: 767.98px) {
  .full-media__content-right, .full-media__content-left {
    padding: 30px 30px 30px 30px;
  }
}
.full-media__content-right {
  width: 100%;
  gap: 20px;
  background: linear-gradient(270deg, #003865 0%, rgba(0, 56, 101, 0) 100%);
}
@media (max-width: 767.98px) {
  .full-media__content-right {
    background: none;
  }
}
.full-media__button {
  height: 50px;
  width: 50px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  cursor: pointer;
  display: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .full-media__button {
    z-index: 0;
  }
}
.full-media__button--active {
  display: block;
}
.full-media__button:hover {
  background-color: #003865;
  color: #fff;
}
.full-media__button-icon {
  cursor: pointer;
}
.full-media__video {
  width: 100%;
  height: 100%;
  min-height: 750px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .full-media__video {
    min-height: 600px;
  }
}
.full-media__video--active {
  display: block;
  object-fit: contain;
  min-height: auto;
}
.full-media__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .full-media__title {
    font-size: 30px;
    line-height: 130%;
  }
}
.full-media__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .full-media__text {
    font-size: 15px;
  }
}

body.global-ats .full-media__button {
  color: #003865;
}

body.global-ats .full-media__button:hover, body.global-ats .full-media__button:focus {
  color: #fff;
}

body.dark-mode .full-media-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .full-media-carousel__arrow:hover .full-media-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .full-media-carousel__arrow:hover .full-media-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Full Width Media  ======*/
/*=============================================
=            Header            =
=============================================*/
.header {
  position: relative;
  background-color: #003865;
  color: #fff;
  padding: 20px 0;
}
.header__error-page {
  padding: 20px 0;
  position: relative;
  background-color: #003865;
}
@media (max-width: 1249.98px) {
  .header {
    padding: 10px 0;
  }
}
.header__inner {
  margin: 0 auto;
  padding: 0 120px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .header__inner {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .header__inner {
    padding: 0 20px;
  }
}
@media (min-width: 1250px) {
  .header__button {
    display: none;
  }
}
.header__logo {
  max-height: 30px;
  width: auto;
}
.header__logo-container {
  margin-right: auto;
}
.header__links {
  gap: 30px;
}
@media (max-width: 1249.98px) {
  .header__links {
    display: none;
  }
}
.header__links-item {
  min-width: fit-content;
}
.header .dropdown {
  position: relative;
  display: inline-block;
}
.header .top {
  height: 20px;
}
.header .bottom {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 160px;
  padding-block: 25px;
  gap: 25px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.header .bottom::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  top: 4px;
  left: 16px;
  transform: translateY(50%) rotate(45deg);
}
.header .dropdown-content {
  display: none;
  position: absolute;
}
.header .dropdown-content a {
  color: black;
  margin: 0 16px;
  text-decoration: none;
  display: flex;
}
.header .dropdown-content a:hover {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: var(--primary-color);
  padding-left: 10px;
  border-left: 1px solid var(--primary-color);
}
.header .dropdown:hover .dropdown-content {
  display: flex;
}
.header__title:hover, .header__title:focus {
  color: var(--primary-color);
}
.header__link {
  font-size: 15px;
  cursor: pointer;
}
.header__link:hover, .header__link:focus {
  color: var(--primary-color);
}

/*=====  End of Header  ======*/
/*=============================================
=            Hero            =
=============================================*/
.hero {
  position: relative;
  gap: 30px;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  overflow: hidden;
}
.hero--large {
  height: 750px;
}
.hero--large .hero__background {
  height: 750px;
}
@media (max-width: 767.98px) {
  .hero--large {
    height: 600px;
  }
  .hero--large .hero__background {
    height: 600px;
  }
}
.hero--small {
  min-height: 540px;
}
.hero--small .hero__background {
  min-height: 540px;
}
@media (max-width: 767.98px) {
  .hero--small {
    min-height: 500px;
  }
  .hero--small .hero__background {
    min-height: 500px;
  }
}
.hero__background-wrapper {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1; /* Positioned behind all other content */
}
.hero__background-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #003865;
  opacity: 0.5;
  z-index: 0;
}
.hero__background-wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("/assets/img/img/hero-shape.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 0;
}
.hero__background-wrapper img {
  max-height: 750px;
  width: 100%;
  height: 100%;
  max-height: 750px;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .hero__background-wrapper img {
    max-height: fit-content;
  }
}
.hero__main {
  width: 100%;
  max-width: 660px;
  padding: 120px 0;
  gap: 30px;
  position: absolute;
  left: 120px;
  bottom: 0px;
}
@media (max-width: 991.98px) {
  .hero__main {
    left: 30px;
  }
}
@media (max-width: 767.98px) {
  .hero__main {
    padding: 60px 0;
    width: calc(100% - 60px);
  }
}
.hero__inner {
  position: relative;
}
@media (max-width: 767.98px) {
  .hero__inner {
    position: static;
  }
}
.hero__heading {
  gap: 10px;
}
.hero__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 40px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .hero__title {
    font-size: 28px;
    line-height: 130%;
  }
}
.hero__subtitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .hero__subtitle {
    font-size: 18px;
    line-height: 130%;
  }
}
.hero__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .hero__text {
    font-size: 16px;
    line-height: 150%;
  }
}

/*=====  End of Hero  ======*/
/*=============================================
=            Icon Card Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.icon-carousel__wrapper {
  padding: 60px 0;
  color: #003865;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .icon-carousel__wrapper {
    padding-bottom: 140px;
  }
}
@media (max-width: 374.98px) {
  .icon-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.icon-carousel__header {
  gap: 10px;
}
.icon-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .icon-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.icon-carousel__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
@media (max-width: 479.98px) {
  .icon-carousel__subtitle {
    font-size: 16px;
  }
}
.icon-carousel__inner {
  position: relative;
}
.icon-carousel__item {
  background-color: #fff;
  padding: 30px;
  gap: 30px;
  white-space: normal;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
@media (max-width: 479.98px) {
  .icon-carousel__item {
    padding: 30px 15px;
  }
}
.icon-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
@media (max-width: 479.98px) {
  .icon-carousel__text {
    font-size: 16px;
  }
}
.icon-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.icon-carousel__image-container {
  min-height: 36px;
  max-height: 36px;
}
.icon-carousel__slides {
  margin: auto !important;
}
.icon-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .icon-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.icon-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.icon-carousel__arrow.glide__arrow--disabled {
  display: none;
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.icon-carousel__arrow.glide__arrow--disabled .icon-carousel__arrow-icon {
  display: none;
}
.icon-carousel__arrow-icon {
  transform: translateY(1px);
}
.icon-carousel__arrow-left {
  position: absolute;
  left: -70px;
}
@media (max-width: 991.98px) {
  .icon-carousel__arrow-left {
    left: auto;
    right: 100px;
  }
}
@media (max-width: 479.98px) {
  .icon-carousel__arrow-left {
    right: 90px;
  }
}
.icon-carousel__arrow-right {
  position: absolute;
  right: -70px;
}
@media (max-width: 991.98px) {
  .icon-carousel__arrow-right {
    right: 30px;
  }
}
.icon-carousel__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .icon-carousel__controls {
    top: calc(100% + 30px);
  }
}
@media (max-width: 374.98px) {
  .icon-carousel__controls {
    top: calc(100% + 80px);
  }
}
.icon-carousel .link {
  margin-top: auto;
}
.icon-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.icon-carousel__range:focus {
  border-color: #fff;
}
.icon-carousel__range-container {
  position: absolute;
  top: 50%;
  top: calc(100% + 45px);
  left: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .icon-carousel__range-container {
    display: none;
  }
}
@media (max-width: 479.98px) {
  .icon-carousel__range-container {
    top: calc(100% + 40px);
  }
}
.icon-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}
.icon-carousel .glide__slides {
  padding: 10px 0 !important;
}

body.dark-mode .icon-carousel__wrapper {
  color: #fff;
}
body.dark-mode .icon-carousel__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .icon-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .icon-carousel__arrow:hover .icon-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .icon-carousel__arrow:hover .icon-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Icon Card Carousel  ======*/
/*=============================================
=            Image Card Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.image-carousel {
  position: relative;
  width: 100%;
}
@media (max-width: 991.98px) {
  .image-carousel {
    padding-right: 0;
  }
}
.image-carousel__wrapper {
  padding: 60px 0 130px;
  color: #003865;
  gap: 30px;
}
@media (max-width: 374.98px) {
  .image-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.image-carousel__header {
  gap: 10px;
}
.image-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .image-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.image-carousel__subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .image-carousel__subtitle {
    font-size: 16px;
  }
}
.image-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .image-carousel__text {
    font-size: 16px;
  }
}
.image-carousel__item {
  white-space: normal;
  background-color: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  flex: 1;
}
.image-carousel__item-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 479.98px) {
  .image-carousel__item-title {
    font-size: 18px;
    line-height: 130%;
  }
}
.image-carousel__item-subtitle {
  color: #003865;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.15px;
}
@media (max-width: 479.98px) {
  .image-carousel__item-subtitle {
    font-size: 16px;
  }
}
.image-carousel__content {
  padding: 30px;
  height: 100%;
  gap: 15px;
}
.image-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .image-carousel__text {
    font-size: 16px;
  }
}
.image-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.image-carousel__image-container {
  min-height: 200px;
  max-height: 200px;
  position: relative;
}
.image-carousel__badge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 15px;
  border-radius: 20px;
  border: 1px solid #003865;
  background-color: #fff;
}
.image-carousel__slides {
  margin: auto !important;
}
.image-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .image-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.image-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.image-carousel__arrow.glide__arrow--disabled {
  display: none;
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.image-carousel__arrow.glide__arrow--disabled .image-carousel__arrow-icon {
  display: none;
}
.image-carousel__arrow-icon {
  transform: translateY(1px);
}
.image-carousel__arrow-left {
  position: absolute;
  right: 100px;
}
@media (max-width: 374.98px) {
  .image-carousel__arrow-left {
    right: 90px;
  }
}
.image-carousel__arrow-right {
  position: absolute;
  right: 30px;
}
.image-carousel__controls {
  position: absolute;
  top: calc(100% + 30px);
  right: 0;
}
@media (max-width: 374.98px) {
  .image-carousel__controls {
    top: calc(100% + 85px);
  }
}
.image-carousel .link {
  margin-top: auto;
}
.image-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.image-carousel__range:focus {
  border-color: #fff;
}
.image-carousel__range-container {
  position: absolute;
  top: calc(100% + 45px);
  left: 120px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .image-carousel__range-container {
    left: 30px;
  }
}
.image-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .image-carousel__wrapper {
  color: #fff;
}
body.dark-mode .image-carousel__badge {
  color: #003865;
}
body.dark-mode .image-carousel__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .image-carousel__item-subtitle {
  color: #fff;
}
body.dark-mode .image-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .image-carousel__arrow:hover .image-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .image-carousel__arrow:hover .image-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Image Card Carousel  ======*/
/*=============================================
=            Image Card Grid            =
=============================================*/
.image-grid {
  padding: 60px 0;
  color: #003865;
}
.image-grid__inner {
  gap: 60px;
}
.image-grid__header {
  width: 100%;
  gap: 20px;
}
.image-grid__header-left {
  max-width: 720px;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .image-grid__header-right {
    width: 100%;
  }
}
.image-grid__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .image-grid__heading {
    font-size: 24px;
    line-height: 130%;
    text-transform: uppercase;
  }
}
.image-grid__text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .image-grid__text {
    font-size: 16px;
  }
}
.image-grid__filters {
  border-radius: 6px;
  min-width: 240px;
  border: 1px solid rgba(0, 56, 101, 0.5);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  padding: 10px 20px;
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("/assets/img/img/caret-down-icon.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  color: rgba(0, 56, 101, 0.5);
}
@media (max-width: 767.98px) {
  .image-grid__filters {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .image-grid__filters {
    min-width: auto;
    width: 100%;
  }
}
.image-grid__filters-container {
  gap: 10px;
  width: 100%;
}
.image-grid__filters-container label {
  margin-bottom: 0;
}
.image-grid__filter-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .image-grid__filter-text {
    font-size: 16px;
  }
}
.image-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-flow: dense;
  gap: 60px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .image-grid__grid {
    gap: 30px;
  }
}
.image-grid__card {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.image-grid__badge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 15px;
  border-radius: 20px;
  border: 1px solid #003865;
  background-color: #fff;
}
.image-grid__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.image-grid__image-container {
  min-height: 200px;
  max-height: 200px;
  position: relative;
}
@media (max-width: 479.98px) {
  .image-grid__image-container {
    min-height: 155px;
    max-height: 155px;
  }
}
.image-grid__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .image-grid__title {
    font-size: 18px;
    line-height: 130%;
  }
}
.image-grid__subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .image-grid__subtitle {
    font-size: 16px;
  }
}
.image-grid__info {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.15px;
}
@media (max-width: 767.98px) {
  .image-grid__info {
    font-size: 16px;
  }
}
.image-grid__content {
  padding: 30px;
  gap: 15px;
  height: 100%;
}
.image-grid__content .link {
  margin-top: auto;
}

body.dark-mode .image-grid {
  color: #fff;
}
body.dark-mode .image-grid__badge {
  color: #003865;
}

/*=====  End of Image Card Grid  ======*/
/*=============================================
=            Icon Card Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.item-carousel__wrapper {
  padding: 60px 0;
  color: #003865;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .item-carousel__wrapper {
    padding-bottom: 140px;
  }
}
@media (max-width: 374.98px) {
  .item-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.item-carousel__header {
  gap: 10px;
}
.item-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .item-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.item-carousel__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
@media (max-width: 479.98px) {
  .item-carousel__subtitle {
    font-size: 16px;
  }
}
.item-carousel__inner {
  position: relative;
}
.item-carousel__item {
  background-color: #fff;
  padding: 30px;
  gap: 30px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
@media (max-width: 479.98px) {
  .item-carousel__item {
    padding: 30px 15px;
  }
}
.item-carousel__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .item-carousel__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.item-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
  text-align: center;
}
@media (max-width: 479.98px) {
  .item-carousel__text {
    font-size: 16px;
  }
}
.item-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.item-carousel__image-container {
  min-height: 64px;
  max-height: 64px;
}
@media (max-width: 479.98px) {
  .item-carousel__image-container {
    min-height: 40px;
    max-height: 40px;
  }
}
.item-carousel__list-item {
  white-space: normal;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 56, 101, 0.1);
}
.item-carousel__list-item:first-child {
  border-top: 1px solid rgba(0, 56, 101, 0.1);
}
.item-carousel__list-item-image {
  height: 40px;
  width: 40px;
  margin: auto;
}
.item-carousel__list-item-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .item-carousel__list-item-text {
    font-size: 16px;
  }
}
.item-carousel__slides {
  margin: auto !important;
}
.item-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .item-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.item-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.item-carousel__arrow.glide__arrow--disabled {
  display: none;
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.item-carousel__arrow.glide__arrow--disabled .item-carousel__arrow-icon {
  display: none;
}
.item-carousel__arrow-icon {
  transform: translateY(1px);
}
.item-carousel__arrow-left {
  position: absolute;
  left: -70px;
}
@media (max-width: 991.98px) {
  .item-carousel__arrow-left {
    left: auto;
    right: 100px;
  }
}
@media (max-width: 479.98px) {
  .item-carousel__arrow-left {
    right: 90px;
  }
}
.item-carousel__arrow-right {
  position: absolute;
  right: -70px;
}
@media (max-width: 991.98px) {
  .item-carousel__arrow-right {
    right: 30px;
  }
}
.item-carousel__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .item-carousel__controls {
    top: calc(100% + 30px);
  }
}
@media (max-width: 374.98px) {
  .item-carousel__controls {
    top: calc(100% + 80px);
  }
}
.item-carousel .link {
  margin-top: auto;
}
.item-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.item-carousel__range:focus {
  border-color: #fff;
}
.item-carousel__range-container {
  position: absolute;
  top: 50%;
  top: calc(100% + 45px);
  left: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .item-carousel__range-container {
    display: none;
  }
}
@media (max-width: 479.98px) {
  .item-carousel__range-container {
    top: calc(100% + 40px);
  }
}
.item-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}
.item-carousel .glide__slides {
  padding: 10px 0 !important;
}

body.dark-mode .item-carousel__wrapper {
  color: #fff;
}
body.dark-mode .item-carousel__item {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .item-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .item-carousel__arrow:hover .item-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .item-carousel__arrow:hover .item-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Icon Card Carousel  ======*/
/*=============================================
=            Item List            =
=============================================*/
.item-list {
  padding: 60px 0;
  color: #003865;
}
.item-list__inner {
  gap: 60px;
}
.item-list__header {
  width: 100%;
  gap: 30px;
  max-width: 840px;
}
.item-list__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .item-list__heading {
    font-size: 24px;
    line-height: 130%;
  }
}
.item-list__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  row-gap: 40px;
  column-gap: 160px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .item-list__grid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
  }
}
.item-list__card {
  gap: 30px;
}
@media (max-width: 767.98px) {
  .item-list__card {
    gap: 20px;
  }
}
.item-list__icon {
  height: 100%;
  width: 100%;
}
.item-list__icon-container {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
}
@media (max-width: 767.98px) {
  .item-list__icon-container {
    min-height: 30px;
    max-height: 30px;
    min-width: 30px;
    max-width: 30px;
  }
}
.item-list__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .item-list__title {
    font-size: 18px;
    line-height: 130%;
  }
}
.item-list__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .item-list__text {
    font-size: 16px;
  }
}
.item-list__content {
  gap: 10px;
}

body.dark-mode .item-list {
  color: #fff;
}

/*=====  End of Item List  ======*/
/*=============================================
=            Large Icons Grid            =
=============================================*/
.large-icons-grid {
  padding: 60px 0;
  color: #003865;
}
@media (max-width: 479.98px) {
  .large-icons-grid {
    padding: 60px 0 140px;
  }
}
@media (max-width: 374.98px) {
  .large-icons-grid {
    padding-bottom: 170px;
  }
}
.large-icons-grid__inner {
  gap: 60px;
  position: relative;
  margin: 0 auto;
  padding: 0 120px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .large-icons-grid__inner {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .large-icons-grid__inner {
    padding-right: 0;
  }
}
.large-icons-grid__header {
  width: 100%;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .large-icons-grid__header {
    padding-right: 30px;
  }
}
.large-icons-grid__header-left {
  max-width: 720px;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .large-icons-grid__header-right {
    width: 100%;
  }
}
.large-icons-grid__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .large-icons-grid__heading {
    font-size: 24px;
    line-height: 130%;
  }
}
.large-icons-grid__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .large-icons-grid__text {
    font-size: 16px;
  }
}
.large-icons-grid__filters {
  border-radius: 6px;
  min-width: 240px;
  border: 1px solid rgba(0, 56, 101, 0.5);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  padding: 10px 20px;
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("/assets/img/img/caret-down-icon.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  color: rgba(0, 56, 101, 0.5);
}
@media (max-width: 767.98px) {
  .large-icons-grid__filters {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .large-icons-grid__filters {
    min-width: auto;
    width: 100%;
  }
}
.large-icons-grid__filters-container {
  gap: 10px;
  width: 100%;
}
.large-icons-grid__filters-container label {
  margin-bottom: 0;
}
.large-icons-grid__filter-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .large-icons-grid__filter-text {
    font-size: 16px;
  }
}
.large-icons-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .large-icons-grid__grid {
    gap: 30px;
    display: none;
  }
}
.large-icons-grid__grid {
  display: grid;
  width: 100%;
}
.large-icons-grid__grid-4-cards {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.large-icons-grid__grid-3-cards {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 60px;
}
.large-icons-grid__grid-2-cards {
  grid-template-columns: repeat(auto-fill, minmax(570px, 1fr));
  gap: 60px;
}
@media (max-width: 479.98px) {
  .large-icons-grid__grid {
    display: none;
  }
}
.large-icons-grid__card {
  overflow: hidden;
  white-space: normal;
  background-color: #fff;
  padding: 30px;
  gap: 30px;
  text-align: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.large-icons-grid__card .link {
  margin-top: auto;
}
.large-icons-grid__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.large-icons-grid__image-container {
  min-height: 64px;
  max-height: 64px;
  position: relative;
}
@media (max-width: 479.98px) {
  .large-icons-grid__image-container {
    min-height: 40px;
    max-height: 40px;
  }
}
.large-icons-grid__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 767.98px) {
  .large-icons-grid__title {
    font-size: 21px;
    line-height: 130%;
  }
}
.large-icons-grid__info {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .large-icons-grid__info {
    font-size: 16px;
  }
}
.large-icons-grid__carousel {
  width: 100%;
}
.large-icons-grid__carousel--padding-right {
  padding-right: 30px;
}
@media (min-width: 480px) {
  .large-icons-grid__carousel {
    display: none;
  }
}
.large-icons-grid__carousel-card {
  overflow: hidden;
  white-space: normal;
  text-align: center;
  background-color: #fff;
  padding: 30px;
  gap: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.large-icons-grid__carousel-controls {
  position: absolute;
  top: calc(100% + 60px);
  right: 0;
}
@media (max-width: 374.98px) {
  .large-icons-grid__carousel-controls {
    top: calc(100% + 75px);
  }
}
.large-icons-grid__carousel-arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .large-icons-grid__carousel-arrow {
    width: 40px;
    height: 40px;
  }
}
.large-icons-grid__carousel-arrow:hover, .large-icons-grid__carousel-arrow:focus {
  color: #fff;
  background-color: #003865;
}
.large-icons-grid__carousel-arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.large-icons-grid__carousel-arrow-icon {
  transform: translateY(1px);
}
.large-icons-grid__carousel-arrow-left {
  position: absolute;
  right: 100px;
}
@media (max-width: 374.98px) {
  .large-icons-grid__carousel-arrow-left {
    right: 90px;
  }
}
.large-icons-grid__carousel-arrow-right {
  position: absolute;
  right: 30px;
}
.large-icons-grid__carousel-range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.large-icons-grid__carousel-range:focus {
  border-color: #fff;
}
.large-icons-grid__carousel-range-container {
  position: absolute;
  top: calc(100% + 45px);
  left: 120px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .large-icons-grid__carousel-range-container {
    left: 30px;
  }
}
.large-icons-grid__carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .large-icons-grid {
  color: #fff;
}
body.dark-mode .large-icons-grid__card {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .large-icons-grid__carousel-card {
  background-color: #003865;
}

/*=====  End of Large Icons Grid  ======*/
/*=============================================
=            Link            =
=============================================*/
.link {
  cursor: pointer;
  padding: 10px 20px;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 15px;
  font-weight: 450;
  line-height: 20px;
  gap: 10px;
  min-width: max-content;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.link__error-page {
  margin-top: 40px;
}
.link--underline .link__label {
  text-decoration: underline;
}
.link--primary {
  background-color: #fff;
  border: 2px solid var(--primary-color);
  color: #003865;
}
.link--primary-simple {
  background-color: transparent;
  color: var(--primary-color);
  padding: 0;
}
.link--primary:hover, .link--primary:focus {
  background-color: #002a4c;
  color: #fff;
}

body.dark-mode .link--primary {
  background-color: #003865;
  border: 2px solid var(--primary-color);
  color: #fff;
}
body.dark-mode .link--primary:hover, body.dark-mode .link--primary:focus {
  background-color: #002a4c;
}

/*=====  End of Link  ======*/
/*=============================================
=            Logo Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.logo-carousel {
  width: 100%;
}
.logo-carousel__wrapper {
  padding: 60px 0;
  color: #003865;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .logo-carousel__wrapper {
    padding-bottom: 140px;
  }
}
@media (max-width: 767.98px) {
  .logo-carousel__wrapper {
    padding-bottom: 60px;
  }
}
.logo-carousel__header {
  gap: 10px;
  text-align: center;
}
.logo-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .logo-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.logo-carousel__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .logo-carousel__subtitle {
    font-size: 16px;
  }
}
.logo-carousel__inner {
  position: relative;
}
@media (max-width: 767.98px) {
  .logo-carousel__inner {
    display: none;
  }
}
.logo-carousel__item {
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 56, 101, 0.05);
}
.logo-carousel__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}
.logo-carousel__image-container {
  min-height: 120px;
  max-height: 120px;
  height: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .logo-carousel__image-container {
    min-height: auto;
    max-height: auto;
  }
}
.logo-carousel__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 40px;
}
@media (min-width: 768px) {
  .logo-carousel__grid {
    display: none;
  }
}
@media (max-width: 479.98px) {
  .logo-carousel__grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
@media (max-width: 374.98px) {
  .logo-carousel__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.logo-carousel__slides {
  margin: auto !important;
}
.logo-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .logo-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.logo-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.logo-carousel__arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.logo-carousel__arrow.glide__arrow--disabled .logo-carousel__arrow-icon {
  display: none;
}
.logo-carousel__arrow-icon {
  transform: translateY(1px);
}
.logo-carousel__arrow-left {
  position: absolute;
  left: -70px;
}
@media (max-width: 991.98px) {
  .logo-carousel__arrow-left {
    left: auto;
    right: 100px;
  }
}
@media (max-width: 479.98px) {
  .logo-carousel__arrow-left {
    right: 90px;
  }
}
.logo-carousel__arrow-right {
  position: absolute;
  right: -70px;
}
@media (max-width: 991.98px) {
  .logo-carousel__arrow-right {
    right: 30px;
  }
}
.logo-carousel__controls {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-25px);
  width: 100%;
}
@media (max-width: 991.98px) {
  .logo-carousel__controls {
    top: calc(100% + 55px);
  }
}

body.dark-mode .logo-carousel__wrapper {
  color: #fff;
}
body.dark-mode .logo-carousel__item {
  background-color: #003865;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
body.dark-mode .logo-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .logo-carousel__arrow:hover .logo-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .logo-carousel__arrow:hover .logo-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Logo Carousel  ======*/
/*=============================================
=            Media Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.media-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0;
}
@media (max-width: 479.98px) {
  .media-carousel {
    padding: 0 30px;
  }
}
.media-carousel__wrapper {
  width: 100%;
  padding: 60px 0 156px;
  color: #003865;
  gap: 30px;
}
@media (max-width: 479.98px) {
  .media-carousel__wrapper {
    padding-bottom: 126px;
  }
}
.media-carousel__item {
  white-space: normal;
  gap: 30px;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.media-carousel__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .media-carousel__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.media-carousel__media {
  position: relative;
  min-height: 240px;
  max-height: 240px;
}
@media (max-width: 479.98px) {
  .media-carousel__media {
    min-height: 158px;
    max-height: 158px;
  }
}
.media-carousel__image, .media-carousel__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.media-carousel__video {
  cursor: pointer;
}
.media-carousel__button {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.media-carousel__button-inner {
  background-color: var(--primary-color);
  height: 50px;
  width: 50px;
  color: #fff;
  z-index: 2;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.media-carousel__button-inner:hover, .media-carousel__button-inner:focus {
  background-color: #003865;
  color: #fff;
}
.media-carousel__button-icon {
  font-size: 20px;
}
.media-carousel__content {
  height: 100%;
  gap: 20px;
  text-align: center;
}
.media-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 767.98px) {
  .media-carousel__text {
    font-size: 16px;
  }
}
.media-carousel .link {
  margin-top: auto;
}
.media-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .media-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.media-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.media-carousel__arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.media-carousel__arrow-icon {
  transform: translateY(1px);
}
.media-carousel__arrow-left {
  position: absolute;
  left: 30px;
}
.media-carousel__arrow-right {
  position: absolute;
  right: 30px;
}
.media-carousel__controls {
  position: absolute;
  width: 100%;
  top: calc(100% + 56px);
  right: 0;
}
@media (max-width: 479.98px) {
  .media-carousel__controls {
    top: calc(100% + 46px);
  }
}

body.dark-mode .media-carousel__wrapper {
  color: #fff;
}
body.dark-mode .media-carousel__item {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
body.dark-mode .media-carousel__content {
  padding-bottom: 30px;
}

/*=====  End of Media Carousel  ======*/
/*=============================================
=            Media With Text            =
=============================================*/
.media-with-text {
  padding: 60px 0;
  color: #003865;
}
.media-with-text__left, .media-with-text__right {
  flex: 1;
}
.media-with-text__inner {
  gap: 120px;
  margin: 60px auto;
  width: 100%;
}
@media (max-width: 767.98px) {
  .media-with-text__inner {
    flex-direction: column-reverse;
    gap: 30px;
  }
}
.media-with-text__inner--reverse {
  flex-direction: row-reverse;
  width: 100%;
}
@media (max-width: 767.98px) {
  .media-with-text__inner--reverse {
    flex-direction: column-reverse;
  }
}
.media-with-text__left {
  box-sizing: border-box;
  flex: 0.8;
  gap: 20px;
}
.media-with-text__right {
  position: relative;
}
.media-with-text__button {
  height: 50px;
  width: 50px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  display: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.media-with-text__button--active {
  display: block;
}
.media-with-text__button:hover, .media-with-text__button:focus {
  background-color: #003865;
  color: #fff;
}
.media-with-text__button-icon {
  cursor: pointer;
}
.media-with-text__video {
  display: none;
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  cursor: pointer;
}
.media-with-text__video--active {
  display: block;
}
.media-with-text__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 8px;
}
.media-with-text__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .media-with-text__title {
    font-size: 24px;
    line-height: 130%;
  }
}
.media-with-text__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .media-with-text__subtitle {
    font-size: 16px;
  }
}
.media-with-text__placeholder {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
  display: none;
}
.media-with-text__placeholder--active {
  display: block;
}

body.dark-mode .media-with-text {
  color: #fff;
}

/*=====  End of Media With Text  ======*/
/*=============================================
=            Image Card Grid            =
=============================================*/
.mini-icons-grid {
  padding: 60px 0;
  color: #003865;
}
@media (max-width: 479.98px) {
  .mini-icons-grid {
    padding: 60px 0 170px;
  }
}
@media (max-width: 374.98px) {
  .mini-icons-grid {
    padding: 60px 0 204px;
  }
}
.mini-icons-grid__inner {
  gap: 60px;
  position: relative;
  margin: 0 auto;
  padding: 0 120px;
  max-width: 1440px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .mini-icons-grid__inner {
    padding: 0 30px;
  }
}
@media (max-width: 479.98px) {
  .mini-icons-grid__inner {
    padding-right: 0;
  }
}
.mini-icons-grid__header {
  width: 100%;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__header {
    padding-right: 30px;
  }
}
.mini-icons-grid__header-left {
  max-width: 720px;
  gap: 20px;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__header-right {
    width: 100%;
  }
}
.mini-icons-grid__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .mini-icons-grid__heading {
    font-size: 24px;
    line-height: 130%;
  }
}
.mini-icons-grid__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .mini-icons-grid__text {
    font-size: 16px;
  }
}
.mini-icons-grid__filters {
  border-radius: 6px;
  min-width: 240px;
  border: 1px solid rgba(0, 56, 101, 0.5);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  padding: 10px 20px;
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("/assets/img/img/caret-down-icon.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  color: rgba(0, 56, 101, 0.5);
}
@media (max-width: 767.98px) {
  .mini-icons-grid__filters {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .mini-icons-grid__filters {
    min-width: auto;
    width: 100%;
  }
}
.mini-icons-grid__filters-container {
  gap: 10px;
  width: 100%;
}
.mini-icons-grid__filters-container label {
  margin-bottom: 0;
}
.mini-icons-grid__filter-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .mini-icons-grid__filter-text {
    font-size: 16px;
  }
}
.mini-icons-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 60px;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__grid {
    gap: 30px;
    display: none;
  }
}
.mini-icons-grid__card {
  overflow: hidden;
  white-space: normal;
  background-color: #fff;
  padding: 30px;
  gap: 30px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.mini-icons-grid__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.mini-icons-grid__image-container {
  min-height: 64px;
  max-height: 64px;
  max-width: 64px;
  min-width: 64px;
  position: relative;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__image-container {
    min-height: 40px;
    max-height: 40px;
    max-width: 40px;
    min-width: 40px;
  }
}
.mini-icons-grid__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .mini-icons-grid__title {
    font-size: 18px;
    line-height: 130%;
  }
}
.mini-icons-grid__info {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .mini-icons-grid__info {
    font-size: 16px;
  }
}
.mini-icons-grid__content {
  gap: 15px;
  height: 100%;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__content {
    gap: 10px;
  }
}
.mini-icons-grid__carousel {
  width: 100%;
}
.mini-icons-grid__carousel--padding-right {
  padding-right: 30px;
}
@media (min-width: 480px) {
  .mini-icons-grid__carousel {
    display: none;
  }
}
.mini-icons-grid__carousel-card {
  overflow: hidden;
  white-space: normal;
  background-color: #fff;
  padding: 30px;
  gap: 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.mini-icons-grid__carousel-controls {
  position: absolute;
  top: calc(100% + 60px);
  right: 0;
}
@media (max-width: 374.98px) {
  .mini-icons-grid__carousel-controls {
    top: calc(100% + 105px);
  }
}
.mini-icons-grid__carousel-arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .mini-icons-grid__carousel-arrow {
    width: 40px;
    height: 40px;
  }
}
.mini-icons-grid__carousel-arrow:hover {
  color: #fff;
  background-color: #003865;
}
.mini-icons-grid__carousel-arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.mini-icons-grid__carousel-arrow-icon {
  transform: translateY(1px);
}
.mini-icons-grid__carousel-arrow-left {
  position: absolute;
  right: 100px;
}
@media (max-width: 374.98px) {
  .mini-icons-grid__carousel-arrow-left {
    right: 90px;
  }
}
.mini-icons-grid__carousel-arrow-right {
  position: absolute;
  right: 30px;
}
.mini-icons-grid__carousel-range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.mini-icons-grid__carousel-range:focus {
  border-color: #fff;
}
.mini-icons-grid__carousel-range-container {
  position: absolute;
  top: calc(100% + 75px);
  left: 120px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .mini-icons-grid__carousel-range-container {
    left: 30px;
  }
}
.mini-icons-grid__carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .mini-icons-grid {
  color: #fff;
}
body.dark-mode .mini-icons-grid__card {
  background-color: #003865;
  color: #fff;
}
body.dark-mode .mini-icons-grid__carousel-card {
  background-color: #003865;
}

/*=====  End of Image Card Grid  ======*/
/*=============================================
=            Menu Mobile            =
=============================================*/
.menu-mobile__overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: -3000px;
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.menu-mobile__overlay--active {
  opacity: 1;
  visibility: visible;
  right: 0;
  top: 100%;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}
.menu-mobile__item--hidden {
  display: none;
}
.menu-mobile__inner {
  height: calc(100% - 50px);
  width: 50%;
  margin-left: auto;
}
.menu-mobile__inner-menu {
  width: 100vw;
  transform: translateX(0);
}
@media (max-width: 767.98px) {
  .menu-mobile__inner {
    width: 100%;
  }
}
.menu-mobile__bottom {
  margin-top: auto;
  gap: 20px;
  flex-wrap: wrap;
}
.menu-mobile__menu {
  position: relative;
  height: 100%;
  background-color: #003865;
  background-color: rgba(0, 56, 101, 0.9);
  padding: 50px 30px;
  gap: 30px;
}
.menu-mobile__link {
  position: relative;
  width: 100%;
  border-left: 2px solid transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .menu-mobile__link {
    font-size: 16px;
  }
}
.menu-mobile__link:hover, .menu-mobile__link:focus {
  border-color: var(--primary-color);
  padding-left: 10px;
  color: var(--primary-color);
}
.menu-mobile__link-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menu-mobile__nested-links {
  width: 100%;
  position: relative;
  border-left: 2px solid transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  flex-direction: column;
  gap: 25px;
}
@media (max-width: 767.98px) {
  .menu-mobile__nested-links {
    font-size: 16px;
    gap: 20px;
  }
}
.menu-mobile__nested-links-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menu-mobile__title {
  position: relative;
  width: 100%;
  border-left: 2px solid transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .menu-mobile__title {
    font-size: 16px;
  }
}
.menu-mobile__title:hover, .menu-mobile__title:focus {
  border-color: var(--primary-color);
  padding-left: 10px;
  color: var(--primary-color);
}
.menu-mobile__title-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.menu-mobile__title-mini {
  color: #808080;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  gap: 10px;
}
.menu-mobile__back-button {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.menu-mobile__back-button-icon {
  position: relative;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
}
.menu-mobile__back-button:hover, .menu-mobile__back-button:focus {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  border-color: var(--primary-color);
  padding-left: 10px;
  color: var(--primary-color);
}
.menu-mobile__back-text {
  padding-left: 10px;
}
.menu-mobile__dividor {
  width: 100%;
  height: 1px;
  background-color: #fff;
}
.menu-mobile--open .menu-mobile__inner-right {
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  transform: translateX(-100%);
}
.menu-mobile__icon {
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.menu-mobile__icon-bar {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.menu-mobile__icon-bar:nth-child(1) {
  top: 0px;
  right: 0;
  left: auto;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon-bar:nth-child(2) {
  top: 13px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon-bar:nth-child(3) {
  top: 26px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 8px;
  left: 0px;
  right: auto;
  width: 100%;
  width: 40px;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.menu-mobile__icon--open .menu-mobile__icon-bar:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 27px;
  left: 0px;
  width: 40px;
}
.menu-mobile__socials {
  gap: 20px;
}
.menu-mobile__social-icon {
  font-size: 24px;
}

/*=====  End of Menu Mobile  ======*/
/*=============================================
=            Modal Video            =
=============================================*/
.modal-video {
  width: 100%;
  position: relative;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
@media (min-width: 1250px) {
  .modal-video {
    width: auto;
  }
}
.modal-video::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-video__inner {
  width: 600px;
  width: 100%;
  padding: 25px;
  position: relative;
}
@media (max-width: 767.98px) {
  .modal-video__inner {
    padding: 0;
  }
}
.modal-video__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .modal-video__close {
    height: 40px;
    width: 40px;
  }
}
.modal-video__close:hover, .modal-video__close:focus {
  background-color: #003865;
  color: #fff;
}
.modal-video__close-icon {
  font-size: 26px;
}
.modal-video__video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

body.global-ats .modal-video__close {
  color: #003865;
}

body.global-ats .modal-video__close:hover, body.global-ats .modal-video__close:focus {
  color: #fff;
}

/*=====  End of Modal Video  ======*/
/*=============================================
=            Page Card Carousel            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.page-carousel__wrapper {
  padding: 60px 0;
  color: #003865;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .page-carousel__wrapper {
    padding-bottom: 140px;
  }
}
@media (max-width: 374.98px) {
  .page-carousel__wrapper {
    padding-bottom: 180px;
  }
}
.page-carousel__header {
  gap: 10px;
}
.page-carousel__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .page-carousel__heading {
    text-align: start;
  }
}
@media (max-width: 479.98px) {
  .page-carousel__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.page-carousel__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
@media (max-width: 479.98px) {
  .page-carousel__subtitle {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .page-carousel__subtitle {
    text-align: start;
  }
}
.page-carousel__inner {
  position: relative;
}
.page-carousel__item {
  background-color: #fff;
  padding: 30px;
  gap: 20px;
  position: relative;
  white-space: normal;
  z-index: 1;
  color: #fff;
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  overflow: hidden;
}
@media (max-width: 479.98px) {
  .page-carousel__item {
    padding: 30px 15px;
    min-height: 400px;
  }
}
.page-carousel__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #003865;
  opacity: 0.5;
  z-index: -1;
}
.page-carousel__item-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 479.98px) {
  .page-carousel__item-title {
    font-size: 21px;
    line-height: 130%;
  }
}
.page-carousel__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .page-carousel__text {
    font-size: 16px;
  }
}
.page-carousel__slides {
  margin: auto !important;
}
.page-carousel__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .page-carousel__arrow {
    width: 40px;
    height: 40px;
  }
}
.page-carousel__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.page-carousel__arrow.glide__arrow--disabled {
  display: none;
}
.page-carousel__arrow-icon {
  transform: translateY(1px);
}
.page-carousel__arrow-left {
  position: absolute;
  left: -70px;
}
@media (max-width: 991.98px) {
  .page-carousel__arrow-left {
    left: auto;
    right: 100px;
  }
}
@media (max-width: 479.98px) {
  .page-carousel__arrow-left {
    right: 90px;
  }
}
.page-carousel__arrow-right {
  position: absolute;
  right: -70px;
}
@media (max-width: 991.98px) {
  .page-carousel__arrow-right {
    right: 30px;
  }
}
.page-carousel__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .page-carousel__controls {
    top: calc(100% + 30px);
  }
}
@media (max-width: 374.98px) {
  .page-carousel__controls {
    top: calc(100% + 80px);
  }
}
.page-carousel__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.page-carousel__range:focus {
  border-color: #fff;
}
.page-carousel__range-container {
  position: absolute;
  top: 50%;
  top: calc(100% + 45px);
  left: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .page-carousel__range-container {
    display: none;
  }
}
@media (max-width: 479.98px) {
  .page-carousel__range-container {
    top: calc(100% + 40px);
  }
}
.page-carousel input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}
.page-carousel .glide__slides {
  padding: 0 !important;
}

body.dark-mode .page-carousel__wrapper {
  color: #fff;
}
body.dark-mode .page-carousel__arrow:hover {
  background-color: white;
}
body.dark-mode .page-carousel__arrow:hover .page-carousel__arrow-icon {
  color: #003865;
}
body.dark-mode .page-carousel__arrow:hover .page-carousel__arrow-icon:focus {
  color: #003865;
}

/*=====  End of Page Card Carousel  ======*/
/*=============================================
=            Pagination            =
=============================================*/
.pagination {
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1440px;
  width: 100%;
}
.pagination--hidden {
  display: none;
}
.pagination__text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
}
.pagination__inner {
  width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: auto;
  border-radius: 2px;
  margin-bottom: 24px;
}
@media (max-width: 479.98px) {
  .pagination__inner {
    border: none;
  }
}
.pagination__inner i {
  font-size: 20px;
  pointer-events: none;
}
.pagination__btn {
  font-family: inherit;
  font-size: 14px;
  line-height: 21px;
  height: 32px;
  min-width: 32px;
  background-color: #003865;
  color: #f9f9f9;
  text-align: center;
  user-select: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.pagination__btn:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.pagination__btn:hover, .pagination__btn:focus {
  background: #001e36;
}
@media (max-width: 479.98px) {
  .pagination__btn {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.pagination__numb.pagination__btn--active {
  background: #001e36;
}
.pagination__numb:hover, .pagination__numb:focus {
  background: #001e36;
}
.pagination__dots {
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

body.dark-mode {
  color: #fff;
}

/*=====  End of Pagination  ======*/
/*=============================================
=            People Grid            =
=============================================*/
.people-grid {
  padding: 60px 0;
  color: #003865;
}
.people-grid__inner {
  gap: 60px;
  position: relative;
}
.people-grid__header {
  width: 100%;
  gap: 20px;
}
@media (max-width: 767.98px) {
  .people-grid__header {
    flex-direction: row;
  }
}
.people-grid__header-left {
  max-width: 720px;
  gap: 20px;
  flex-direction: column;
}
@media (max-width: 479.98px) {
  .people-grid__header-right {
    width: 100%;
  }
}
.people-grid__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .people-grid__heading {
    font-size: 24px;
    line-height: 130%;
  }
}
.people-grid__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .people-grid__text {
    font-size: 16px;
  }
}
.people-grid__filters {
  border-radius: 6px;
  min-width: 240px;
  border: 1px solid rgba(0, 56, 101, 0.5);
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  padding: 10px 20px;
  position: relative;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("/assets/img/img/caret-down-icon.svg");
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  color: rgba(0, 56, 101, 0.5);
}
@media (max-width: 767.98px) {
  .people-grid__filters {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .people-grid__filters {
    min-width: auto;
    width: 100%;
  }
}
.people-grid__filters-container {
  gap: 10px;
  width: 100%;
}
.people-grid__filters-container label {
  margin-bottom: 0;
}
.people-grid__filter-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .people-grid__filter-text {
    font-size: 16px;
  }
}
.people-grid__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 40px;
  width: 100%;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .people-grid__grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  }
}
.people-grid__item {
  flex-direction: column;
  overflow: hidden;
  white-space: normal;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
}
@media (max-width: 479.98px) {
  .people-grid__item {
    flex-direction: row;
  }
}
.people-grid__image {
  height: 100%;
  width: 100%;
  min-height: 360px;
  border-radius: 5px;
  object-fit: cover;
  vertical-align: middle;
}
@media (max-width: 479.98px) {
  .people-grid__image {
    min-height: 100px;
    min-width: 100px;
  }
}
.people-grid__image-container {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 479.98px) {
  .people-grid__image-container {
    max-width: 100px;
    min-width: 100px;
  }
}
.people-grid__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .people-grid__title {
    font-size: 18px;
    line-height: 130%;
  }
}
.people-grid__content {
  gap: 10px;
  height: 100%;
  padding: 20px;
}
.people-grid__content .link {
  margin-top: auto;
}
@media (max-width: 479.98px) {
  .people-grid__content {
    padding: 5px 5px 5px 20px;
  }
}

body.dark-mode .people-grid {
  color: #fff;
}
body.dark-mode .people-grid__item {
  background-color: #003865;
  color: #fff;
}

/*=====  End of People Grid  ======*/
/*=============================================
=            Plain Text Component            =
=============================================*/
.plain-text {
  width: 100%;
}
.plain-text__wrapper {
  padding: 60px 0;
  color: #003865;
}
.plain-text__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .plain-text__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.plain-text__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .plain-text__text {
    font-size: 16px;
  }
}
.plain-text__link {
  margin-top: 20px;
}

body.dark-mode .plain-text__wrapper {
  color: #fff;
}

/*=====  End of Plain Text Component  ======*/
/*=============================================
=            Quick Links            =
=============================================*/
.quick-links {
  position: relative;
  background-color: #003865;
  color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.quick-links__list {
  padding: 20px 0;
}
@media (max-width: 767.98px) {
  .quick-links__list::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 100%;
    right: 60px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
.quick-links__title {
  margin-right: 10px;
  width: max-content;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.8;
}
@media (max-width: 767.98px) {
  .quick-links__title {
    font-size: 16px;
  }
}
.quick-links__list {
  gap: 20px;
}
.quick-links__dropdown {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: #003865;
  color: #fff;
  -webkit-appearance: none;
  height: 100%;
  max-width: 60px;
  background-image: url("/assets/img/img/chevron-down-white.svg");
  background-position-x: calc(100% - 20px);
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  z-index: 9999;
  cursor: pointer;
  width: 100%;
}
@media (min-width: 768px) {
  .quick-links__dropdown {
    display: none;
  }
}
.quick-links__dropdown label {
  margin-bottom: 0;
}
.quick-links__option {
  background-color: #003865;
}
.quick-links__select {
  height: 100%;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: inherit;
}
.quick-links__link {
  display: block;
  width: max-content;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .quick-links__link {
    font-size: 16px;
  }
}
.quick-links__link:hover, .quick-links__link:focus {
  color: var(--primary-color);
}
@media (max-width: 767.98px) {
  .quick-links .link {
    display: none;
  }
}

/*=====  End of Quick Links  ======*/
.rte--inline {
  margin: 0;
  max-width: none;
}
.rte--white {
  color: #fff;
}
.rte__title {
  margin-bottom: 10px;
}
.rte__subtitle {
  margin-bottom: 56px;
}
.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5 {
  display: block;
  margin-bottom: 20px;
}
.rte p,
.rte ol,
.rte ul {
  display: block;
  margin-bottom: 5px;
}
.rte ul,
.rte ol,
.rte li {
  margin-left: 16px;
}
.rte ul,
.rte ol {
  margin-top: 20px;
}
.rte ul {
  list-style: disc;
}
.rte ol {
  list-style: decimal;
}
.rte strong {
  font-weight: 700;
}
.rte a.underline {
  text-decoration: underline;
}
.rte h1 {
  font-size: 40px;
  font-weight: 450;
  line-height: 120%;
}
@media (max-width: 767.98px) {
  .rte h1 {
    font-size: 28px;
  }
}
.rte h2 {
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
}
@media (max-width: 767.98px) {
  .rte h2 {
    font-size: 24px;
  }
}
.rte h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
}
@media (max-width: 767.98px) {
  .rte h3 {
    font-size: 21px;
  }
}
.rte h4 {
  font-size: 25px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.5px;
}
@media (max-width: 767.98px) {
  .rte h4 {
    font-size: 18px;
  }
}
.rte p {
  font-size: 18px;
}
@media (max-width: 767.98px) {
  .rte p {
    font-size: 16px;
  }
}
.rte .p--small {
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .rte .p--small {
    font-size: 14px;
  }
}
.rte img {
  height: fit-content;
}

/*=====  End of RTE  ======*/
.scroll-carousel {
  display: flex;
  width: 150vw;
  will-change: transform;
  transition: transform 0.4s ease-out;
}
@media screen and (max-width: 1600px) {
  .scroll-carousel {
    transition: transform 0.3s ease-out;
  }
}
.scroll-carousel__title {
  font-size: 35px;
  font-weight: 420;
}
.scroll-carousel__clipped {
  margin-top: 30px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
@media (max-width: 767.98px) {
  .scroll-carousel__clipped {
    display: none;
  }
}
.scroll-carousel__clipped.visible {
  opacity: 1;
}
.scroll-carousel__container {
  max-width: 166px;
  margin: 0 30px;
}
.scroll-carousel__image {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.mb-scroll-carousel {
  display: block;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .mb-scroll-carousel {
    display: none;
  }
}
.mb-scroll-carousel__image-container {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  align-items: center;
  justify-items: center;
}

/*=============================================
=            Stats Component            =
=============================================*/
.stats {
  padding: 60px 0;
  color: #003865;
}
.stats__inner {
  gap: 60px;
  width: 100%;
}
.stats__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 60px;
  width: 100%;
}
@media (max-width: 479.98px) {
  .stats__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.stats__item {
  gap: 20px;
  text-align: center;
}
.stats__content {
  font-size: 50px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
.stats__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .stats__text {
    font-size: 16px;
  }
}

body.dark-mode .stats {
  color: #fff;
}

/*=====  End of Stats Component  ======*/
/*=============================================
=            Testimonials            =
=============================================*/
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.testimonials {
  width: 100%;
}
@media (max-width: 479.98px) {
  .testimonials {
    padding-right: 0 !important;
  }
}
.testimonials__wrapper {
  padding: 60px 0;
  color: #003865;
  gap: 30px;
}
@media (max-width: 991.98px) {
  .testimonials__wrapper {
    padding-bottom: 140px;
  }
}
@media (max-width: 374.98px) {
  .testimonials__wrapper {
    padding-bottom: 180px;
  }
}
.testimonials__header {
  gap: 10px;
}
.testimonials__heading {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 35px;
  font-weight: 450;
  line-height: 120%;
  text-transform: uppercase;
}
@media (max-width: 479.98px) {
  .testimonials__heading {
    font-size: 24px;
    font-weight: 450;
    line-height: 130%;
  }
}
.testimonials__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
@media (max-width: 479.98px) {
  .testimonials__subtitle {
    font-size: 16px;
  }
}
.testimonials__inner {
  position: relative;
}
.testimonials__item {
  padding: 30px;
  gap: 30px;
  white-space: normal;
}
@media (max-width: 479.98px) {
  .testimonials__item {
    padding: 30px 15px;
  }
}
.testimonials__item-inner {
  max-width: 720px;
  gap: 30px;
}
@media (max-width: 479.98px) {
  .testimonials__item-inner {
    gap: 20px;
  }
}
.testimonials__item-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
  text-align: center;
}
@media (max-width: 479.98px) {
  .testimonials__item-title {
    font-size: 16px;
    line-height: 130%;
  }
}
.testimonials__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  white-space: normal;
  text-align: center;
}
@media (max-width: 479.98px) {
  .testimonials__text {
    font-size: 16px;
  }
}
.testimonials__person {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .testimonials__person {
    font-size: 16px;
  }
}
.testimonials__person-container {
  gap: 10px;
}
.testimonials__role {
  color: rgba(0, 56, 101, 0.5);
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 479.98px) {
  .testimonials__role {
    font-size: 16px;
  }
}
.testimonials__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.testimonials__image-container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonials__icon {
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.testimonials__icon--normal {
  display: block;
}
.testimonials__icon--darkmode {
  display: none;
}
.testimonials__icon-container {
  height: 64px;
  width: 64px;
}
@media (max-width: 479.98px) {
  .testimonials__icon-container {
    height: 40px;
    width: 40px;
  }
}
.testimonials__arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 5px;
  z-index: 99;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .testimonials__arrow {
    width: 40px;
    height: 40px;
  }
}
.testimonials__arrow:hover {
  color: #fff;
  background-color: #003865;
}
.testimonials__arrow.glide__arrow--disabled {
  background-color: rgba(0, 56, 101, 0.05);
  color: rgba(0, 56, 101, 0.3);
  cursor: default;
  pointer-events: none;
}
.testimonials__arrow-icon {
  transform: translateY(1px);
}
.testimonials__arrow-left {
  position: absolute;
  left: -70px;
}
@media (max-width: 991.98px) {
  .testimonials__arrow-left {
    left: auto;
    right: 100px;
  }
}
@media (max-width: 479.98px) {
  .testimonials__arrow-left {
    right: 90px;
  }
}
.testimonials__arrow-right {
  position: absolute;
  right: -70px;
}
@media (max-width: 991.98px) {
  .testimonials__arrow-right {
    right: 30px;
  }
}
.testimonials__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .testimonials__controls {
    top: calc(100% + 30px);
  }
}
@media (max-width: 374.98px) {
  .testimonials__controls {
    top: calc(100% + 80px);
  }
}
.testimonials__range {
  width: 200px !important;
  background: #e5ebef;
  border-radius: 8px;
  height: 6px;
  outline: none;
  border-radius: 8px;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.testimonials__range:focus {
  border-color: #fff;
}
.testimonials__range-container {
  position: absolute;
  top: 50%;
  top: calc(100% + 45px);
  left: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .testimonials__range-container {
    display: none;
  }
}
@media (max-width: 479.98px) {
  .testimonials__range-container {
    top: calc(100% + 40px);
  }
}
.testimonials input[type=range]::-webkit-slider-thumb {
  width: 6px;
  width: var(--thumb-size);
  height: 6px;
  border-radius: 8px;
  opacity: 1;
  background: var(--primary-color);
  -webkit-appearance: none;
  cursor: pointer;
}

body.dark-mode .testimonials__wrapper {
  color: #fff;
}
body.dark-mode .testimonials__arrow:hover {
  background-color: white;
}
body.dark-mode .testimonials__arrow:hover .testimonials__arrow-icon {
  color: #003865;
}
body.dark-mode .testimonials__arrow:hover .testimonials__arrow-icon:focus {
  color: #003865;
}
body.dark-mode .testimonials__role {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-mode .testimonials__icon--normal {
  display: none;
}
body.dark-mode .testimonials__icon--darkmode {
  display: block;
}
body.dark-mode .testimonials__item {
  background-color: #003865;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/*=====  End of Testimonials  ======*/
/*=============================================
=            Video            =
=============================================*/
.video {
  margin: 60px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .video {
    max-height: none;
  }
}
.video--active {
  max-height: none;
}
.video__inner {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.video__button {
  height: 50px;
  width: 50px;
  color: #fff;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  cursor: pointer;
  display: none;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
@media (max-width: 767.98px) {
  .video__button {
    z-index: 0;
  }
}
.video__button--active {
  display: block;
}
.video__button:hover, .video__button:focus {
  background-color: #003865;
  color: #fff;
}
.video__button-icon {
  cursor: pointer;
}
.video__video {
  width: 100%;
  height: 100%;
  min-width: 720px;
  max-width: 720px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .video__video {
    min-width: auto;
    max-width: none;
  }
}
.video__video--active {
  display: block;
  object-fit: contain;
  min-height: auto;
  min-width: auto;
}
@media (max-width: 767.98px) {
  .video__video--active {
    max-height: none;
  }
}

body.global-ats .video__button {
  color: #003865;
}

body.global-ats .video__button:hover, body.global-ats .video__button:focus {
  color: #fff;
}

/*=====  End of Video  ======*/