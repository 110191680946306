html,
body {
    font-family: $font-family--base;
    font-weight: 400;
    overflow-x: hidden;
    color: $text--black;
    scroll-behavior: smooth;
}

body {
    background-color: #F4F8FB;
    background-image: url('/assets/img/img/body-pattern.svg');
    background-size: contain;
    background-repeat: repeat-y;
    background-position: center;

    @include media-breakpoint-down(md) {
        background-size: auto;
    }
}

body.dark-mode {
    background-image: url('/assets/img/img/body-pattern-dark.svg');
    background-color: $bg--dark;
}

body.no-background {
    background-image: none;
}

.container.container {
    margin: 0 auto;
    padding: 0 120px;
    max-width: 1440px;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 0 30px;
    }

    &::before, &::after {
        content: none;
    }

    &--padding-left {
        padding-right: 0;
    }

    &--full {
        width: 100%;
        margin: 0 auto;
    }

    &--awkward {
        margin: 0 auto;
        max-width: $container-width--default;
        padding: 0 200px;

        @include media-breakpoint-down(lg) {
            padding: 0 16px;
        }
    }

    &--has-margin {
        padding-top: 60px;
        padding-bottom: 60px;

        @include media-breakpoint-down(md) {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
}

.hidden {
    display: none;
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.glide__slides {
    padding: 10px 0 !important;
}

input[type="range"][min="0"][max="0"] {
    display: none;
}

body.global-ats div[data-glide-el="controls"] {
    button {
        color: $text--dark;
    }

    button:hover, button:focus {
        color: $text--white;
    }

    button.glide__arrow--disabled {
        color: $text--white;
    }
}

.errors-container {
    &__inner {
        gap: 20px;
    }

    &__title {
        font-family: $font-family--alt;
        font-size: 50px;
        font-weight: 450;
        line-height: 120%;
        text-transform: uppercase;

        @include media-breakpoint-down(md){
            font-size:30px;
        }
    }
}