/*=============================================
=            Media Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.full-media-carousel {
  position: relative;
  width: 100%;
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;

  &__item {
    white-space: normal;
    position: relative;
    -webkit-mask-image: url("/assets/img/img/full-media-mask.png");
    mask-image: url("/assets/img/img/full-media-mask.png");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-size: cover;
    min-height: 100%;
    max-height: 100%;

    @include transition;

    &--no-mask {
      -webkit-mask-image: none;
      mask-image: none;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;

      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 130%;
      }
    }
  }

  &__media {
    position: relative;
    z-index: 0;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $bg--dark;
      opacity: 0.5;
      z-index: 2;
    }

    @include media-breakpoint-down(md) {
      min-height: 600px;
      max-height: 600px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 20px;
    text-align: center;
    color: $text--white;
    max-width: 420px;

    @include media-breakpoint-down(sm) {
      min-width: 100%;
      padding: 0 100px;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 70px;
    }

    .link.link--primary {
      min-width: auto;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    white-space: normal;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  //  glide styles
  &__arrow {
    width: 50px;
    height: 50px;
    color: $text--white;
    background-color: $bg--primary;
    border-radius: 5px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }

    &:hover,
    &:focus {
      color: $text--white;
      background-color: $bg--dark;
    }

    &.glide__arrow--disabled {
      background-color: rgb(0, 56, 101, 0.05);
      color: rgb(0, 56, 101, 0.3);
      cursor: default;
      pointer-events: none;
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      left: 20px;
    }

    &-right {
      position: absolute;
      right: 20px;
    }
  }

  &__controls {
    position: absolute;
    width: 100%;
    top: calc(50% - 25px);
    right: 0;
  }

  .glide__slides {
    padding: 0 !important;
  }
}

/*=====  End of Media Carousel  ======*/
