/*=============================================
=            Large Icons Grid            =
=============================================*/

.large-icons-grid {
  padding: 60px 0;
  color: $text--dark;

  @include media-breakpoint-down(sm) {
    padding: 60px 0 140px;
  }

  @include media-breakpoint-down(xs) {
    padding-bottom: 170px;
  }

  &__inner {
    gap: 60px;
    position: relative;
    margin: 0 auto;
    padding: 0 120px;
    max-width: 1440px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      padding: 0 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &__header {
    width: 100%;
    gap: 20px;

    @include media-breakpoint-down(sm) {
      padding-right: 30px;
    }

    &-left {
      max-width: 720px;
      gap: 20px;
    }

    &-right {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__filters {
    border-radius: 6px;
    min-width: 240px;
    border: 1px solid rgb(0, 56, 101, 0.5);
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;
    padding: 10px 20px;
    position: relative;
    -webkit-appearance: none;
    background-color: $bg--white;
    background-image: url("/assets/img/img/caret-down-icon.svg");
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 9999;
    cursor: pointer;
    color: rgb(0, 56, 101, 0.5);

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    @include media-breakpoint-down(sm) {
      min-width: auto;
      width: 100%;
    }

    &-container {
      gap: 10px;
      width: 100%;

      label {
        margin-bottom: 0;
      }
    }
  }

  &__filter {
    &-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;

    @include transition;

    @include media-breakpoint-down(sm) {
      gap: 30px;
      display: none;
    }
  }

  &__grid {
    display: grid;
    width: 100%;

    &-4-cards {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
    }

    &-3-cards {
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
      gap: 60px;
    }

    &-2-cards {
      grid-template-columns: repeat(auto-fill, minmax(570px, 1fr));
      gap: 60px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__card {
    overflow: hidden;
    white-space: normal;
    background-color: $bg--white;
    padding: 30px;
    gap: 30px;
    text-align: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .link {
      margin-top: auto;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle;

    &-container {
      min-height: 64px;
      max-height: 64px;
      position: relative;

      @include media-breakpoint-down(sm) {
        min-height: 40px;
        max-height: 40px;
      }
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 120%;

    @include media-breakpoint-down(md) {
      font-size: 21px;
      line-height: 130%;
    }
  }

  &__info {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__carousel {
    width: 100%;

    &--padding-right {
      padding-right: 30px;
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }

    &-card {
      overflow: hidden;
      white-space: normal;
      text-align: center;
      background-color: $bg--white;
      padding: 30px;
      gap: 20px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    &-controls {
      position: absolute;
      top: calc(100% + 60px);
      right: 0;

      @include media-breakpoint-down(xs) {
        top: calc(100% + 75px);
      }
    }

    &-arrow {
      width: 50px;
      height: 50px;
      color: $text--white;
      background-color: $bg--primary;
      border-radius: 5px;
      z-index: 99;
      font-size: 20px;
      cursor: pointer;

      @include transition;

      @include media-breakpoint-down(sm) {
        width: 40px;
        height: 40px;
      }

      &:hover,
      &:focus {
        color: $text--white;
        background-color: $bg--dark;
      }

      &.glide__arrow--disabled {
        background-color: rgb(0, 56, 101, 0.05);
        color: rgb(0, 56, 101, 0.3);
        cursor: default;
        pointer-events: none;
      }

      &-icon {
        transform: translateY(1px);
      }

      &-left {
        position: absolute;
        right: 100px;

        @include media-breakpoint-down(xs) {
          right: 90px;
        }
      }

      &-right {
        position: absolute;
        right: 30px;
      }
    }

    &-range {
      width: 200px !important;
      background: $bg--off-white;
      border-radius: 8px;
      height: 6px;
      outline: none;
      border-radius: 8px;
      -webkit-appearance: none;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      @include transition;

      &:focus {
        border-color: $white;
      }

      &-container {
        position: absolute;
        top: calc(100% + 45px);
        left: 120px;
        width: 100%;

        @include media-breakpoint-down(lg) {
          left: 30px;
        }
      }
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 6px;
      width: var(--thumb-size);
      height: 6px;
      border-radius: 8px;
      opacity: 1;
      background: $bg--primary;
      -webkit-appearance: none;
      cursor: pointer;
    }
  }
}

body.dark-mode {
  .large-icons-grid {
    color: $text--white;

    &__card {
      background-color: $bg--dark;
      color: $text--white;
    }

    &__carousel-card {
      background-color: $bg--dark;
    }
  }
}

/*=====  End of Large Icons Grid  ======*/
