/*=============================================
=            Header            =
=============================================*/

.header {
  position: relative;
  background-color: $bg--dark;
  color: $text--white;
  padding: 20px 0;

  &__error-page {
    padding: 20px 0;
    position: relative;
    background-color: $bg--dark;
  }

  @include media-breakpoint-down(xl) {
    padding: 10px 0;
  }

  &__inner {
    margin: 0 auto;
    padding: 0 120px;
    max-width: 1440px;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 0 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }

  &__button {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__logo {
    max-height: 30px;
    width: auto;

    &-container {
      margin-right: auto;
    }
  }

  &__links {
    gap: 30px;

    @include media-breakpoint-down(xl) {
      display: none;
  }

    &-item {
      min-width: fit-content;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .top {
    height: 20px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    background-color: $bg--white;
    min-width: 160px;
    padding-block: 25px;
    gap: 25px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;

    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: $bg--white;
      top: 4px;
      left: 16px;
      transform: translateY(50%) rotate(45deg);
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
  }
  
  .dropdown-content a {
    color: black;
    margin: 0 16px;
    text-decoration: none;
    display: flex;
  }
  
  .dropdown-content a:hover {
    @include transition; 
    color: $text--primary;

    padding-left: 10px;
    border-left: 1px solid $primary;
  }
  
  .dropdown:hover .dropdown-content {
    display: flex;
  }
  
  &__title {

    &:hover, &:focus {
      color: $text--primary;
    }
  }
  
  &__link {
    font-size: 15px;
    
    cursor: pointer;

    &:hover, &:focus {
      color: $text--primary;
    }
  }
}

/*=====  End of Header  ======*/
