/*=============================================
=            Stats Component            =
=============================================*/

.stats {
  padding: 60px 0;
  color: $text--dark;

  &__inner {
    gap: 60px;
    width:100%;
  }
  
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 60px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    gap: 20px;
    text-align: center;
  }

  &__content {
    font-size: 50px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}

body.dark-mode {
  .stats {
    color: $text--white;
  }
}

/*=====  End of Stats Component  ======*/
