.rte {

    &--inline {
        margin: 0;
        max-width: none;
    }

    &--white {
        color: $text--white;
    }

    &__title {
        margin-bottom: 10px;
    }

    &__subtitle {        
        margin-bottom: 56px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        display: block;
        margin-bottom: 20px;
    }

    p,
    ol,
    ul {
        display: block;
        margin-bottom: 5px;
    }

    ul,
    ol,
    li {
        margin-left: 16px;
    }

    ul,
    ol {
        margin-top: 20px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: 700;
    }

    a.underline {
        text-decoration: underline;
    }

    h1 {
        font-size: 40px;
        font-weight: 450;
        line-height: 120%; 

        @include media-breakpoint-down(md) {
            font-size: 28px;
        }
    }

    h2 {
        font-size: 35px;
        font-weight: 450;
        line-height: 120%;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
    }

    h3 {
        font-size: 30px;
        font-weight: 700;
        line-height: 120%; 

        @include media-breakpoint-down(md) {
            font-size: 21px;
        }
    }

    h4 {
        font-size: 25px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.5px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    p {
        font-size: 18px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    .p--small {
        font-size: 16px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    img {
        height: fit-content;
    }
}

/*=====  End of RTE  ======*/
