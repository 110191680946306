/*=============================================
=            Full Width Media            =
=============================================*/

.full-media {
  margin: 60px auto;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    max-height: none;
  }

  &--active {
    max-height: none;
  }
  
  &__inner {
    position: relative;
    height: 100%;
  }

  &__content {
    color: $text--white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: none;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      background: linear-gradient(0deg, #003865 0%, rgba(0, 56, 101, 0.00) 100%);
    }

    &--active {
      display: flex;
    }

    &-right, &-left {
      flex: 1;
      height: 100%;
      width: 100%;
      padding: 120px;

      @include media-breakpoint-down(lg) {
        padding: 30px 30px 30px 120px;
      }

      @include media-breakpoint-down(md) {
        padding: 30px 30px 30px 30px;
      }
    }

    &-right {
      width: 100%;
      gap: 20px;
      background: linear-gradient(270deg, #003865 0%, rgba(0, 56, 101, 0.00) 100%);
    
      @include media-breakpoint-down(md) {
        background: none;
      }
    }
  }

  &__button {
    height: 50px;
    width: 50px;
    color: $text--white;
    font-size: 20px;
    background-color: $bg--primary;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    cursor: pointer;
    display: none;

    @include transition;
    
    @include media-breakpoint-down(md) {
      z-index: 0;
    }

    &--active {
      display: block;
    }
    
    &:hover{
      background-color: $bg--dark;
      color: $text--white;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    min-height: 750px;
    object-fit: cover;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      min-height: 600px;
    }

    &--active {
      display: block;
      object-fit: contain;
      min-height: auto;
    }
  }

  &__title {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;
    
    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }
}

body.global-ats {
  .full-media__button {
    color: $text--dark;
  }
}

body.global-ats {
  .full-media__button:hover, .full-media__button:focus {
    color: $text--white;
  }
}

body.dark-mode {
  .full-media-carousel{
    &__arrow:hover{
      background-color: white;
  
      .full-media-carousel__arrow-icon{
        color: $text--dark;
      }
      .full-media-carousel__arrow-icon:focus{
        color: $text--dark
      }
    }
  }
}

/*=====  End of Full Width Media  ======*/
