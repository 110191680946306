/*=============================================
=            Accordion            =
=============================================*/

.accordion {
  padding: 60px 0;
  color: $text--dark;

  &__inner {
    gap: 60px;
    position: relative;
  }

  &__header {
    width: 100%;
    gap: 30px;
    max-width: 840px;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%; 
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%; 
    }
  }
  
  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__main {
    width: 100%;
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.5px;
    user-select: none;
    max-width: 70%;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 130%;
      max-width: 90%;
    }
  }

  &__content {
    cursor: default;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    max-width: 70%;

    @include media-breakpoint-down(md) {
      max-width: none;
      font-size: 16px;
    }
  }

  &__item {
    width: 100%;
    border-top: 1px solid rgba(0, 56, 101, 0.10);

    &:last-child {
      border-bottom: 1px solid rgba(0, 56, 101, 0.10);
    }

    &:hover {
      cursor: pointer;
    }

    &[open] {
      .accordion__icon--minus {
        display: flex;
      }
  
      .accordion__icon--plus {
        display: none;
      }
    }

    &-summary {
      position: relative;
      list-style: none;
      padding: 10px;
    }
  
    &::-webkit-details-marker {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
    height: 40px;
    width: 40px;

    &--minus {
      display: none;
    }

    &--plus {
      display: flex;
    }
  }
}

body.dark-mode {
  .accordion {
    color: $text--white;

    &__item {
      border-top: 1px solid rgba(255, 255, 255, 0.50);
  
      &:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.50);
      }
    }
  }
}

/*=====  End of Accordion  ======*/
