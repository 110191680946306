/*=============================================
=            Feature Banner            =
=============================================*/

.feature-banner {
  position: relative;
  color: $text--white;
  background-color: $bg--white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @include media-breakpoint-down(md) {
    min-height: 600px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $bg--dark;
    opacity: 0.5;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url("/assets/img/img/hero-shape.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: -1;
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: none;
    visibility: hidden;
    opacity: 0;

    &--active {
      display: flex;
      visibility: visible;
      opacity: 1;
    }

    &-container {
      white-space: normal;
      position: relative;
      -webkit-mask-image: url("/assets/img/img/feature-banner-mask.svg");
      mask-image: url("/assets/img/img/feature-banner-mask.svg");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-size: contain;
      -webkit-mask-size: cover;
      min-height: 400px;
      max-height: 400px;
      max-width: 1200px;
      margin-top: 105px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        margin-top: 80px;
        min-height: 200px;
        -webkit-mask-size: contain;
        -webkit-mask-image: url("/assets/img/img/feature-banner-mask-mobile.svg");
        mask-image: url("/assets/img/img/feature-banner-mask-mobile.svg");
      }
      }
  }

  &__content {
    width: 100%;
    max-width: 1200px;
    gap: 30px;
    transform: translateY(-60px);
    text-align: center;

    @include media-breakpoint-down(md) {
      transform: translateY(0);
    }
  }

  &__heading {
    gap: 10px;
    max-width: 960px;

    &-margin {
      margin-top: 60px;
    }
  }

  &__title {
    font-family: $font-family--alt;
    font-size: 40px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 28px;
      line-height: 130%;
    }
  }

  &__subtitle {
    font-size: 25px;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.5px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%; 

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

/*=====  End of Feature Banner  ======*/
