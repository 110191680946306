/*=============================================
=            People Grid            =
=============================================*/

.people-grid {
  padding: 60px 0;
  color: $text--dark;

  &__inner {
    gap: 60px;
    position: relative;
  }

  &__header {
    width: 100%;
    gap: 20px;

    @include media-breakpoint-down(md) {
      flex-direction: row;
    }

    &-left {
      max-width: 720px;
      gap: 20px;
      flex-direction: column;
    }

    &-right {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 30px;
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%;
    }
  }

  &__text {
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__filters {
    border-radius: 6px;
    min-width: 240px;
    border: 1px solid rgb(0, 56, 101, 0.5);
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;
    padding: 10px 20px;
    position: relative;
    -webkit-appearance: none;
    background-color: $bg--white;
    background-image: url("/assets/img/img/caret-down-icon.svg");
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 9999;
    cursor: pointer;
    color: rgb(0, 56, 101, 0.5);

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    @include media-breakpoint-down(sm) {
      min-width: auto;
      width: 100%;
    }

    &-container {
      gap: 10px;
      width: 100%;

      label {
        margin-bottom: 0;
      }
    }
  }

  &__filter {
    &-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 40px;
    width: $container-width--wide;

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
    }

    @include transition;
  }

  &__item {
    flex-direction: column;
    overflow: hidden;
    white-space: normal;
    background-color: $bg--white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 5px;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    min-height: 360px;
    border-radius: 5px;
    object-fit: cover;
    vertical-align: middle;

    @include media-breakpoint-down(sm) {
      min-height: 100px;
      min-width: 100px;
    }

    &-container {
      width: 100%;
      height: 100%;
      position: relative;

      @include media-breakpoint-down(sm) {
        max-width: 100px;
        min-width: 100px;
      }
    }
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.5px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 130%;
    }
  }

  &__content {
    gap: 10px;
    height: 100%;
    padding: 20px;

    .link {
      margin-top: auto;
    }

    @include media-breakpoint-down(sm) {
      padding: 5px 5px 5px 20px;
    }
  }
}

body.dark-mode {
  .people-grid {
    color: $text--white;

    &__item {
      background-color: $bg--dark;
      color: $text--white;
    }
  }
}

/*=====  End of People Grid  ======*/
