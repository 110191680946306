/*=============================================
=            Modal Video            =
=============================================*/

.modal-video {
  width: 100%;
  position: relative;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  @include media-breakpoint-up(xl) {
    width: auto;
  }

  &::backdrop {
    background-color: rgb(0, 0, 0, 0.7);
  }

  &__inner {
      width: 600px;
      width: 100%;
      padding: 25px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 0
      }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background-color: $bg--primary;
    color: $text--white;
    border-radius: 50%;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(md) {
      height: 40px;
      width: 40px;
    }

    &:hover, &:focus {
      background-color: $bg--dark;
      color: $text--white;
    }

    &-icon {
      font-size: 26px;
    }
  }

  &__video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

body.global-ats {
  .modal-video__close {
    color: $text--dark;
  }
}

body.global-ats {
  .modal-video__close:hover, .modal-video__close:focus {
    color: $text--white;
  }
}

/*=====  End of Modal Video  ======*/
