/*=============================================
=            Menu Mobile            =
=============================================*/

.menu-mobile {
  &__overlay {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -3000px;
    background-color: rgba(0, 0, 0, 0.15);
    color: $text--white;

    @include transition;

    &--active {
      opacity: 1;
      visibility: visible;
      right: 0;
      top: 100%;
      width: 100%;
      height: 100vh;
      z-index: 9999;
    }
  }

  &__item {
    &--hidden {
      display: none;
    }
  }

  &__inner {
    height: calc(100% - 50px);
    width: 50%;
    margin-left: auto;

    &-menu {
      width: 100vw;
      transform: translateX(0);
    }

    @include media-breakpoint-down(md) {
      width: $container-width--wide;
    }
  }

  &__bottom {
    margin-top: auto;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__menu {
    position: relative;
    height: 100%;
    background-color: $bg--dark;
    background-color: rgba(0, 56, 101, 0.9);
    padding: 50px 30px;
    gap: 30px;
  }

  &__link {
    position: relative;
    width: 100%;
    border-left: 2px solid transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    &:hover, &:focus {
      border-color: $primary;
      padding-left: 10px;
      color: $text--primary;
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__nested-links {
    width: 100%;
    position: relative;
    border-left: 2px solid transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    flex-direction: column;
    gap: 25px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      gap: 20px;
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__title {
    position: relative;
    width: 100%;
    border-left: 2px solid transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    &:hover, &:focus {
      border-color: $primary;
      padding-left: 10px;
      color: $text--primary;
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__title-mini {
    color: $grey;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    gap: 10px;
  }

  &__back-button {
    color: $white;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &-icon {
      position: relative;
      left: 0;
      top: 60%;
      transform: translateY(-50%);
    }

    &:hover, &:focus {

      @include transition; 

      border-color: $primary;
      padding-left: 10px;
      color: $text--primary;
    }
  }

  &__back-text {
    padding-left: 10px;
  }

  &__dividor {
    width: 100%;
    height: 1px;
    background-color: $white;
  }

  &--open {
    .menu-mobile__inner-right {

      @include transition; 

      transform: translateX(-100%);
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    &-bar {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: white;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    &-bar:nth-child(1) {
      top: 0px;
      right: 0;
      left: auto;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &-bar:nth-child(2) {
      top: 13px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &-bar:nth-child(3) {
      top: 26px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &--open .menu-mobile__icon-bar:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -1px;
      left: 8px;
      left: 0px;
      right: auto;
      width: 100%;
      width: 40px;
    }

    &--open .menu-mobile__icon-bar:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &--open .menu-mobile__icon-bar:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 27px;
      left: 0px;
      width: 40px;
    }
  }

  &__socials {
    gap: 20px;
  }
  
  &__social {
    &-icon {
      font-size: 24px;
    }
  }
}

/*=====  End of Menu Mobile  ======*/
