/*=============================================
=            Video            =
=============================================*/

.video {
  margin: 60px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    max-height: none;
  }

  &--active {
    max-height: none;
  }
  
  &__inner {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__button {
    height: 50px;
    width: 50px;
    color: $text--white;
    font-size: 20px;
    background-color: $bg--primary;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    cursor: pointer;
    display: none;

    @include transition;
    
    @include media-breakpoint-down(md) {
      z-index: 0;
    }

    &--active {
      display: block;
    }
    
    &:hover, &:focus {
      background-color: $bg--dark;
      color: $text--white;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    min-width: 720px;
    max-width: 720px;
    object-fit: cover;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      min-width: auto;
      max-width: none;
    }

    &--active {
      display: block;
      object-fit: contain;
      min-height: auto;
      min-width: auto;

      @include media-breakpoint-down(md) {
        max-height: none;
      }
    }
  }
}

body.global-ats {
  .video__button {
    color: $text--dark;
  }
}

body.global-ats {
  .video__button:hover, .video__button:focus {
    color: $text--white;
  }
}

/*=====  End of Video  ======*/
