/*=============================================
=            Page Card Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.page-carousel {
  &__wrapper {
    padding: 60px 0;
    color: $text--dark;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 140px;
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 180px;
    }
  }

  &__header {
    gap: 10px;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%; 
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      text-align:start;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      font-weight: 450;
      line-height: 130%;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }

    @include media-breakpoint-down(md) {
      text-align: start;
    }
  }

  &__inner {
    position: relative;
  }

  &__item {
    background-color: $bg--white;
    padding: 30px;
    gap: 20px;
    position: relative;
    white-space: normal;
    z-index: 1;
    color: $text--white;
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
      min-height: 400px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $bg--dark;
      opacity: 0.5;
      z-index: -1;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;

      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 130%; 
      }
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    white-space: normal;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  //  glide styles
  &__slides {
    margin: auto !important;
  }

  &__arrow {
    width: 50px;
    height: 50px;
    color: $text--white;
    background-color: $bg--primary;
    border-radius: 5px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }

    &:hover{
      color: $text--white;
      background-color: $bg--dark;
    }

    &.glide__arrow--disabled {
      display:none;
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      left: -70px;

    @include media-breakpoint-down(lg) {
      left: auto;
      right: 100px;
    }

    @include media-breakpoint-down(sm) {
      right: 90px;
    }
    }

    &-right {
      position: absolute;
      right: -70px;

    @include media-breakpoint-down(lg) {
      right: 30px;
    }
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;

    @include media-breakpoint-down(lg) {
      top: calc(100% + 30px);
    }

    @include media-breakpoint-down(xs) {
      top: calc(100% + 80px);
    }
  }

  &__range {
    width: 200px !important;
    background: $bg--off-white;
    border-radius: 8px;
    height: 6px;
    outline: none;
    border-radius: 8px;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    
    @include transition;

    &:focus {
      border-color: $white;
    }

    &-container {
      position: absolute;
      top: 50%;
      top: calc(100% + 45px);
      left: 0;
      width: 100%;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        top: calc(100% + 40px);
      }
    }
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 6px;
    width: var(--thumb-size);
    height: 6px;
    border-radius: 8px;
    opacity: 1;
    background:$bg--primary ;
    -webkit-appearance: none;
    cursor: pointer;
  }

  .glide__slides {
    padding: 0 !important;
  }
}

body.dark-mode {
  .page-carousel__wrapper {
    color: $text--white;
  }

  .page-carousel__arrow:hover{
    background-color: white;

    .page-carousel__arrow-icon{
      color: $text--dark;
    }
    .page-carousel__arrow-icon:focus{
      color: $text--dark
    }
  }
}

/*=====  End of Page Card Carousel  ======*/
