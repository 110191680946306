/*=============================================
=            Pagination            =
=============================================*/

.pagination {
    gap: 16px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1440px;
    width: 100%;

    &--hidden {
        display: none;
    }

    &__text {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-top: 24px;
    }

    &__inner {
        width: fit-content;
        border: 1px solid rgba(255, 255, 255, 0.5);
        margin: auto;
        border-radius: 2px;
        margin-bottom: 24px;

        @include media-breakpoint-down(sm) {
            border: none;
        }

        i {
            font-size: 20px;
            pointer-events: none;
        }
    }

    &__btn {
        font-family: inherit;
        font-size: 14px;
        line-height: 21px;
        height: 32px;
        min-width: 32px;
        background-color: $bg--dark;
        color: #f9f9f9;
        text-align: center;
        user-select: none;
        border: none;
        cursor: pointer;

        @include transition;

        &:not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.5);
        }

        &:hover,
        &:focus {
            background: $bg--dark-blue;
        }

        @include media-breakpoint-down(sm) {
            border: 1px solid rgba(255, 255, 255, 0.5);
        }
    }

    &__numb {
        &.pagination__btn--active {
            background: $bg--dark-blue;
        }

        &:hover,
        &:focus {
            background: $bg--dark-blue;
        }
    }

    &__dots {
        color: rgba(255, 255, 255, 0.8);
        pointer-events: none;
    }
}

body.dark-mode {
    color: $text--white;
}

/*=====  End of Pagination  ======*/
