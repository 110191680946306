/*=============================================
=            Logo Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.logo-carousel {
  width: 100%;

  &__wrapper {
    padding: 60px 0;
    color: $text--dark;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 140px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 60px;
    }
  }

  &__header {
    gap: 10px;
    text-align: center;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%; 
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      font-weight: 450;
      line-height: 130%;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__inner {
    position: relative;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__item {
    background-color: $bg--white;
    box-shadow: 0px 5px 20px 0px rgba(0, 56, 101, 0.05);
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle;

    &-container {
      min-height: 120px;
      max-height: 120px;
      height: 100%;
      width: 100%;

      @include media-breakpoint-down(md) {
        min-height: auto;
        max-height: auto;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 40px;

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }

    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  //  glide styles
  &__slides {
    margin: auto !important;
  }

  &__arrow {
    width: 50px;
    height: 50px;
    color: $text--white;
    background-color: $bg--primary;
    border-radius: 5px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }

    &:hover{
      color: $text--white;
      background-color: $bg--dark;
    }

    &.glide__arrow--disabled {
      background-color: rgb(0, 56, 101, 0.05);
      color: rgb(0, 56, 101, 0.3);
      cursor: default;
      pointer-events: none;

      .logo-carousel__arrow-icon {
        display: none;
      }
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      left: -70px;

    @include media-breakpoint-down(lg) {
      left: auto;
      right: 100px;
    }

    @include media-breakpoint-down(sm) {
      right: 90px;
    }
    }

    &-right {
      position: absolute;
      right: -70px;

    @include media-breakpoint-down(lg) {
      right: 30px;
    }
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-25px);
    width: 100%;

    @include media-breakpoint-down(lg) {
      top: calc(100% + 55px);
    }
  }
}

body.dark-mode {
  .logo-carousel{
    
    &__wrapper {
      color: $text--white;
    }

    &__item{
      background-color: $bg--dark;
      box-shadow: 0 4px 12px rgba(0,0,0,.1);
    }
    &__arrow:hover{
      background-color: white;
  
      .logo-carousel__arrow-icon{
        color: $text--dark;
      }
      .logo-carousel__arrow-icon:focus{
        color: $text--dark
      }
    }
  }
}

/*=====  End of Logo Carousel  ======*/
