/*=============================================
=            Feature Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.feature-carousel {
  position: relative;
  width: 100%;

  @include media-breakpoint-down(lg) {
    padding-right: 0;
  }

  &__wrapper {
    padding: 60px 0 130px;
    color: $text--dark;
    gap: 30px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 180px;
    }
  }

  &__header {
    gap: 10px;
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      font-weight: 450;
      line-height: 130%;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__item {
    white-space: normal;
    background-color: $bg--white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &-title {
      font-size: 35px;
      font-weight: 450;
      line-height: 120%;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }

    &-subtitle {
      color: $text--dark;
      font-size: 25px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.5px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 130%;
      }
    }
  }

  &__content {
    padding: 30px;
    height: 100%;
    gap: 15px;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    white-space: normal;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;

    &-container {
      min-height: 360px;
      max-height: 360px;
      position: relative;

      @include media-breakpoint-down(sm) {
        min-height: 320px;
        max-height: 320px;
      }
    }
  }

  //  glide styles
  &__slides {
    margin: auto !important;
  }

  &__arrow {
    width: 50px;
    height: 50px;
    color: $text--white;
    background-color: $bg--primary;
    border-radius: 5px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: $text--white;
      background-color: $bg--dark;
    }

    &.glide__arrow--disabled {
      display: none;
      background-color: rgb(0, 56, 101, 0.05);
      color: rgb(0, 56, 101, 0.3);
      cursor: default;
      pointer-events: none;

      .feature-carousel__arrow-icon {
        display: none;
      }
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      right: 100px;

      @include media-breakpoint-down(xs) {
        right: 90px;
      }
    }

    &-right {
      position: absolute;
      right: 30px;
    }
  }

  &__controls {
    position: absolute;
    top: calc(100% + 30px);
    right: 0;

    @include media-breakpoint-down(xs) {
      top: calc(100% + 85px);
    }
  }

  .link {
    margin-top: auto;
  }

  &__range {
    width: 200px !important;
    background: #e5ebef;
    border-radius: 8px;
    height: 6px;
    outline: none;
    border-radius: 8px;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    @include transition;

    &:focus {
      border-color: $white;
    }

    &-container {
      position: absolute;
      top: calc(100% + 45px);
      left: 120px;
      width: 100%;

      @include media-breakpoint-down(lg) {
        left: 30px;
      }
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 6px;
    width: var(--thumb-size);
    height: 6px;
    border-radius: 8px;
    opacity: 1;
    background: $bg--primary;
    -webkit-appearance: none;
    cursor: pointer;
  }
}

body.dark-mode {
  .feature-carousel__wrapper {
    color: $text--white;
  }

  .feature-carousel__item {
    background-color: $bg--dark;
    color: $text--white;

    &-subtitle {
      color: $text--white;
    }
  }

  .feature-carousel {
    &__arrow:hover {
      background-color: white;

      .feature-carousel__arrow-icon {
        color: $text--dark;
      }
      .feature-carousel__arrow-icon:focus {
        color: $text--dark;
      }
    }
  }
}

/*=====  End of Feature Carousel  ======*/
