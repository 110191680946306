/*=============================================
=            Filter Dropdown            =
=============================================*/

.filters {
    width: 100%;
    min-width: 200px;
    padding: 0 6px;

    &:nth-of-type(2) {
        position: relative;
        padding-top: 24px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 6px);
            height: 1px;
            background-color: white;
            opacity: 0.5;
            max-width: 298px;
        }
    }

    &-list {
        @include media-breakpoint-up(md) {
            padding: 0px;

            .filters__dropdown-button {
                padding: 10px 0 0px;
                border: none;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
            }

            .filters__dropdown-label {
                background-color: $bg--blue;
                color: $text--white;
                border-radius: 4px;
                padding: 8px 12px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;

                &:hover {
                    padding-left: 20px;
                }

                &--active {
                    background-color: $bg--dark-blue;
                }

                &:not(:last-child) {
                    border-bottom: none;
                }
            }

            .filters__bottom {
                display: none;
            }

            .filters__dropdown-content {
                position: static;
                opacity: 1;
                visibility: visible;
                gap: 12px;
                background-color: $bg--blue;
                z-index: 0;
                max-height: none;
            }

            .filters__dropdown-button-text {
                font-size: 14.18px;
                line-height: 20px;
                font-weight: 400;
                text-transform: uppercase;
            }

            .filters__dropdown-button-icon {
                display: none;
            }
        }
    }

    &__bottom {
        padding: 16px 0 0;
        gap: 24px;
        display: none;

        &--active {
            display: flex;
        }

        @include media-breakpoint-down(sm) {
            align-items: center;
            padding: 16px 8px 0;
            min-height: auto;
        }
    }

    &__dropdowns {
        width: 100%;
        gap: 32px;

        @include media-breakpoint-down(lg) {
            gap: 16px;
        }

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
    }

    &__dropdown {
        flex: 1;
        gap: 10px;

        &:first-child {
            .filters__dropdown-button {
                max-width: 451px;

                @include media-breakpoint-down(lg) {
                    max-width: none;
                }
            }
        }

        &:nth-child(2) {
            @include media-breakpoint-down(sm) {
                .filters__dropdown-content {
                    left: auto;
                    right: 0;
                }
            }

            @include media-breakpoint-down(xs) {
                .filters__dropdown-content {
                    left: 0;
                    right: auto;
                }
            }
        }

        &-button {
            font-family: inherit;
            color: $text--white;
            position: relative;
            border: 1px solid $white;
            border-radius: 4px;
            padding: 10px 14px 10px 8px;
            flex: 1;
            gap: 8px;
            cursor: pointer;

            &-text {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }

            &-icon {
                font-size: 24px;
            }
        }

        &-content {
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            width: 100%;
            background-color: $white;
            border-radius: 3px;
            min-width: 160px;
            max-height: 230px;
            overflow: auto;
            z-index: $above;
            opacity: 0;
            visibility: hidden;

            @include transition;

            &--active {
                opacity: 1;
                visibility: visible;
            }
        }

        &-label {
            color: $text--black;
            width: 100%;
            text-align: start;
            display: block;
            cursor: pointer;
            padding: 8px 20px;
            background-color: $white;
            font-family: inherit;
            font-weight: 400;
            margin-bottom: 0;

            @include transition;

            &:not(:last-child) {
                border-bottom: 1px solid $white;
            }

            &--active {
                background-color: $bg--dark-blue;
                color: $text--white;
            }

            &--disabled {
                pointer-events: none;
            }
        }

        &-input {
            display: none;
        }
    }

    &__tags {
        width: 100%;
        gap: 10px;
    }

    &__tag {
        font-family: inherit;
        background-color: $bg--dark-blue;
        color: $text--white;
        padding: 2px 12px;
        gap: 8px;
        border-radius: 100px;
        cursor: pointer;
        border: 1px solid transparent;

        @include transition;

        &:hover,
        &:focus {
            border-color: $white;
        }

        &-name {
            font-size: 14.18px;
            font-weight: 400;
            line-height: 20px;
            text-transform: uppercase;
        }

        &-icon {
            font-size: 18px;
        }
    }
}

body.dark-mode {
    .button--primary {
        background-color: $button--bg;
        border: 2px solid $bg--primary;
        color: $text--white;

        &:hover,
        &:focus {
            background-color: $button--hover;
        }
    }
}

/*=====  End of Filter Dropdown  ======*/
