/*=============================================
=            Footer            =
=============================================*/

.footer {
  background-color: $bg--dark;
  color: $text--white;
  padding: 120px 0 60px;
  gap: 60px;

  &__error-page {
    padding: 20px 0;
    position: relative;
    background-color: $bg--dark;
  }

  @include media-breakpoint-down(md) {
    gap: 30px;
    padding-top: 60px;
  }

  &__inner {
    gap: 30px;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }

    @include media-breakpoint-down(xs) {
      display: flex;
    }
  }

  &__column {
    flex: 1;
  }

  &__column:first-child {
    min-width: 360px;
    margin-right: 90px;
    gap: 30px;

    @include media-breakpoint-down(xl) {
      margin-right: 0px;
      min-width: 300px;
    }

    @include media-breakpoint-down(md) {
      grid-column: span 2;
      margin-bottom: 5px;
    }
  }

  &:last-child {
    @include media-breakpoint-down(md) {
      grid-column: span 2;
    }
  }

  &__column:not(:first-child) {
    gap: 10px;
  }

  &__logo {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    object-fit: contain;

    &-container {
      width: 224px;
    }
  }

  &__second-logo {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    object-fit: contain;

    &-container {
      width: 224px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%; 
    letter-spacing: -0.4px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 130%;
    }
  }

  &__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__caption {
    font-size: 18px;
    font-weight: 500;
    line-height: 48px; 

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__links {
    gap: 10px;
  }

  &__link {
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
    color: $text--white;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    @include transition;

    &:hover, &:focus {
      color: $text--primary;
    }
  }

  &__bottom {

    &-inner {
      width: 100%;
      gap: 30px;
      flex-wrap: wrap;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
        flex-direction: column-reverse;
      }
    }

    &-left {
      font-family: $font-family--other;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
    }

    &-link {
      font-family: $font-family--other;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      @include transition;

      &:hover, &:focus {
        color: $text--primary;
      }
    }

    &-right {
      gap: 20px;
    }
  }

  &__socials {
    gap: 20px;
  }

  &__social{
    height: 20px;
    width: 20px;

    @include transition;

    &:hover, &:focus {
      color: $text--primary;
    }

    &-icon {
      font-size: 20px;
    }
  }
}

/*=====  End of Footer  ======*/