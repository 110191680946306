/*=============================================
=            Media Carousel            =
=============================================*/

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.media-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0;

  @include media-breakpoint-down(sm) {
    padding: 0 30px;
  }

  &__wrapper {
    width: 100%;
    padding: 60px 0 156px;
    color: $text--dark;
    gap: 30px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 126px;
    }
  }

  &__item {
    white-space: normal;
    gap: 30px;

    @include transition;

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;

      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 130%;
      }
    }
  }

  &__media {
    position: relative;
    min-height: 240px;
    max-height: 240px;

    @include media-breakpoint-down(sm) {
      min-height: 158px;
      max-height: 158px;
    }
  }

  &__image,
  &__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__video {
    cursor: pointer;
  }

  &__button {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 1;

    &-inner {
      background-color: $bg--primary;
      height: 50px;
      width: 50px;
      color: $text--white;
      z-index: 2;

      @include transition;

      &:hover,
      &:focus {
        background-color: $bg--dark;
        color: $text--white;
      }
    }

    &-icon {
      font-size: 20px;
    }
  }

  &__content {
    height: 100%;
    gap: 20px;
    text-align: center;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    white-space: normal;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .link {
    margin-top: auto;
  }

  //  glide styles
  &__arrow {
    width: 50px;
    height: 50px;
    color: $text--white;
    background-color: $bg--primary;
    border-radius: 5px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    @include transition;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: $text--white;
      background-color: $bg--dark;
    }

    &.glide__arrow--disabled {
      background-color: rgb(0, 56, 101, 0.05);
      color: rgb(0, 56, 101, 0.3);
      cursor: default;
      pointer-events: none;
    }

    &-icon {
      transform: translateY(1px);
    }

    &-left {
      position: absolute;
      left: 30px;
    }

    &-right {
      position: absolute;
      right: 30px;
    }
  }

  &__controls {
    position: absolute;
    width: 100%;
    top: calc(100% + 56px);
    right: 0;

    @include media-breakpoint-down(sm) {
      top: calc(100% + 46px);
    }
  }
}

body.dark-mode {
  .media-carousel {
    &__wrapper {
      color: $text--white;
    }

    &__item {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    &__content {
      padding-bottom: 30px;
    }
  }
}

/*=====  End of Media Carousel  ======*/
