/*=============================================
=            Quick Links            =
=============================================*/

.quick-links {
  position: relative;
  background-color: $bg--dark;
  color: $text--white;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &__list {
    padding: 20px 0;

    @include media-breakpoint-down(md) {
      &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 100%;
        right: 60px;
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }

  &__title {
    margin-right: 10px;
    width: max-content;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.8;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__list {
    gap: 20px;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: $bg--dark;
    color: $text--white;
    -webkit-appearance: none;
    height: 100%;
    max-width: 60px;
    background-image: url("/assets/img/img/chevron-down-white.svg");
    background-position-x: calc($container-width--wide - 20px);
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 9999;
    cursor: pointer;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: none;
    }

    label {
      margin-bottom: 0;
    }
  }

  &__option {
    background-color: $bg--dark;
  }

  &__select {
    height: 100%;
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: inherit;
  }

  &__link {
    display: block;
    width: max-content;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    &:hover,
    &:focus {
      color: $text--primary;
    }
  }

  .link {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

/*=====  End of Quick Links  ======*/
