/*=============================================
=            Media With Text            =
=============================================*/

.media-with-text {
  padding: 60px 0;
  color: $text--dark;

  &__left, &__right {
    flex: 1;
  }

  &__inner {
    gap: 120px;
    margin: 60px auto;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      gap: 30px;
    }

    &--reverse {
      flex-direction: row-reverse;
      width: 100%;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }

  &__left {
    box-sizing: border-box;
    flex: 0.8;
    gap: 20px;
  }

  &__right {
    position: relative;
  }

  &__button {
    height: 50px;
    width: 50px;
    color: $text--white;
    font-size: 20px;
    background-color: $bg--primary;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    display: none;

    @include transition;

    &--active {
      display: block;
    }
    
    &:hover, &:focus {
      background-color: $bg--dark;
      color: $text--white;
    }

    &-icon {
      cursor: pointer;
    }
  }

  &__video {
    display: none;
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;

    &--active {
      display: block;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 8px;
  }

  &__title {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;
    
    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__placeholder {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    cursor: pointer;
    display: none;

    &--active {
      display: block;
    }
  }
}

body.dark-mode {
  .media-with-text {
    color: $text--white;
  }
}

/*=====  End of Media With Text  ======*/
