/*=============================================
=            Careers Grid            =
=============================================*/

.careers-grid {
  padding: 60px 0;
  color: $text--dark;

  &__inner {
    gap: 60px;
  }

  &__header {
    width: 100%;
    gap: 20px;

    &-left {
      max-width: 600px;
      gap: 20px;
    }

    &-right {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__heading {
    font-family: $font-family--alt;
    font-size: 35px;
    font-weight: 450;
    line-height: 120%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 130%;
      text-transform: uppercase;
    }
  }

  &__text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__filters {
    border-radius: 6px;
    min-width: 240px;
    border: 1px solid rgb(0, 56, 101, 0.5);
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;
    padding: 10px 20px;
    position: relative;
    background-color: $bg--white;
    -webkit-appearance: none;
    background-image: url("/assets/img/img/caret-down-icon.svg");
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 9999;
    cursor: pointer;
    color: rgb(0, 56, 101, 0.5);

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }

    @include media-breakpoint-down(sm) {
      min-width: auto;
      width: 100%;
    }

    &-container {
      gap: 10px;
      width: 100%;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }

      label {
        margin-bottom: 0;
      }
    }
  }

  &__filter {
    &-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 60px;
    transition: all 0.3s ease;

    @include transition;

    @include media-breakpoint-down(sm) {
      gap: 30px;
    }
  }

  &__item {
    background-color: $bg--white;
    padding: 30px;
    white-space: normal;
    gap: 15px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;

      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 130%;
      }
    }

    &-subtitle {
      color: $text--dark;
      font-size: 18px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.15px;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    &-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    .link {
      margin-top: auto;
    }
  }
}

body.dark-mode {
  .careers-grid {
    color: $text--white;

    &__item {
      background-color: $bg--dark;
      color: $text--white;

      &-subtitle {
        color: $text--white;
      }
    }
  }
}

/*=====  End of Careers Grid  ======*/
